import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React, { useContext } from "react";

import logo from "../../assets/logo.svg";
import { MainListItems } from "./listItems";
import { Help } from "@mui/icons-material";
import { AuthContext } from "../../providers/AuthProvider";

export const MainSidebar = ({
  visible,
  setVisible,
  supportModal,
  setSupportModal,
}) => {
  const { user } = useContext(AuthContext);

   function isMobileDevice() {
    return window.innerWidth <= 800;
  }

  // let width = isMobileDevice() ? '310px' : '20vw';

  return (
    <Drawer
      variant="temporary"
      open={visible}
      onClose={() => setVisible(false)}
      sx={{
        width: 180,
      }}
      anchor="top"
      PaperProps={{
        style: {
          backgroundColor: "#fff",
          justifyContent: "space-between",
          // padding: "30px 0px",
          margin: isMobileDevice() ? "30px": 'auto',
          marginTop: isMobileDevice() ? "80px" : "150px",
          width: !isMobileDevice() && '320px',
          borderRadius: "24px",
        },
      }}
    >
      <div style={{ height: "100%", width: '100%'}}>
        {/* <Divider /> */}
        {/* <List> */}
          <MainListItems />
        {/* </List> */}
      </div>
    </Drawer>
  );
};
