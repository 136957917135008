import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import { Loader } from "../../components/Loader";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import Screen1 from "./Screen1";
import iconHeart from "../../assets/Vector (23).png";
import Screen2 from "./Screen2";

import "./CulturalFIt.css";

const CulturalFIt = () => {
  const [currentScreen, setCurrentScreen] = useState("screen1");

  const renderScreen = () => {
    switch (currentScreen) {
      case "screen2":
        return <Screen2 setCurrentScreen={setCurrentScreen} />;
      default:
        return <Screen1 setCurrentScreen={setCurrentScreen} />;
    }
  };

  function isMobileDevice() {
    return window.innerHeight <= 788;
  }


  return (
    <AuthorizedLayout>
      <div className="containerCulturalFIt" style={{ marginBottom: "50px" }}>
        {renderScreen(currentScreen)}
      </div>
      <div
        className={
          currentScreen === "screen1" ? isMobileDevice() ? "footerMobile" : "footerMobileFixed" : "footerMobileFixed"
        }
      >
        Desenvolvido com
        <img src={iconHeart} alt="" />
        <strong>© eComLovers®</strong>
      </div>
    </AuthorizedLayout>
  );
};

export default CulturalFIt;
