import React, { useContext } from "react";
import { PolarArea } from "react-chartjs-2";
import "chart.js/auto";
import { AuthContext } from "../../providers/AuthProvider";

const CulturalChart = () => {
  const { user } = useContext(AuthContext);

  const fitData = user?.culturalFitResult;

  const data = {
    labels: ["Executor", "Idealizador", "Comunicador", "Analítico"],
    datasets: [
      {
        label: "Fit Cultural",
        data: [fitData?.e, fitData?.i, fitData?.c, fitData?.a],
        backgroundColor: ["#BEFCF9"],
        borderColor: "#57BAB8",
        borderWidth: 1,
        hoverBackgroundColor: ["#57BAB8"],
      },
    ],
  };

  const config = {
    type: "polarArea",
    data: data,
    options: {
      responsive: true,
      scales: {
        r: {
          pointLabels: {
            display: true,
            centerPointLabels: true,
            color: "#57BAB8",
            font: {
              size: 12,
              weight: 400,
            },
          },
          ticks: {
            display: false,
          },
          grid: {
            display: true,
          },
          angleLines: {
            display: true, // Ativa as linhas angulares
            color: "#57BAB8", // Cor das linhas angulares
            lineWidth: 1, // Espessura das linhas angulares
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const total = data.datasets[0].data.reduce(
                (acc, value) => acc + value,
                0
              );
              const value = data.datasets[0].data[tooltipItem.dataIndex];
              const percentage = ((value / total) * 100).toFixed(0); // Calcula a porcentagem
              return ` ${percentage}%`; // Exibe o valor e a porcentagem
            },
          },
        },
      },
    },
  };

  return (
    <PolarArea data={data} options={config.options} plugins={config.plugins} />
  );
};

export default CulturalChart;
