import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../providers/AuthProvider";
import iconCheck from "../../assets/Frame 20 (4).png";
import iconNotCheck from "../../assets/Frame 21.png";
import iconHeart from "../../assets/Vector (23).png";

import "./CheckProfile.css";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Button,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

const CheckProfile = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
    userAVatar: user?.avatar !== null
  };

  // console.log(user);
  

  return (
    <AuthorizedLayout>
      <div className="containerCheckProfile">
     
          <div className="fotterCheck">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "10px",
                paddingBottom: "15px",
                marginTop: "30px",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
                color="secondary"
                style={{ textTransform: "none" }}
                onClick={() => navigate("/settings")}
              >
                <ArrowBackIos
                  sx={{
                    width: "15px",
                    height: "15px",
                    color: "white",
                    padding: "5px",
                    backgroundColor: "#57BAB8",
                    borderRadius: "5px",
                    paddingLeft: "10px",
                  }}
                />
                <div className="textSkil">Perfil</div>
              </Button>
            </div>
            <div className="contentContainer2">
              <div
                className="cardCheck"
                style={{
                  backgroundColor:
                    checkList["isTalentKnowledgeAreaFilled"] && "#2D2D2D",
                  border:
                    !checkList["isTalentKnowledgeAreaFilled"] &&
                    "1px solid #E3E3E3",
                  position: "relative",
                }}
                onClick={() => navigate("/skills")}
              >
                {!checkList["isTalentKnowledgeAreaFilled"] && (
                  <div className="iconNotific"></div>
                )}
                <img
                  src={
                    checkList["isTalentKnowledgeAreaFilled"]
                      ? iconCheck
                      : iconNotCheck
                  }
                  style={{ width: "20px", height: "20px" }}
                  alt="icon"
                />
                <div
                  className="textCard"
                  style={{
                    color:
                      checkList["isTalentKnowledgeAreaFilled"] && "#FFFFFF",
                  }}
                >
                  Habilidades adicionais
                </div>
              </div>

              <div
                className="cardCheck"
                style={{
                  backgroundColor:
                    checkList["isTalentKnowledgeSkillFilled"] && "#2D2D2D",
                  border:
                    !checkList["isTalentKnowledgeSkillFilled"] &&
                    "1px solid #E3E3E3",
                  position: "relative",
                }}
                onClick={() => navigate("/knowledge")}
              >
                {!checkList["isTalentKnowledgeSkillFilled"] && (
                  <div className="iconNotific"></div>
                )}
                <img
                  src={
                    checkList["isTalentKnowledgeSkillFilled"]
                      ? iconCheck
                      : iconNotCheck
                  }
                  style={{ width: "20px", height: "20px" }}
                  alt="icon"
                />
                <div
                  className="textCard"
                  style={{
                    color:
                      checkList["isTalentKnowledgeSkillFilled"] && "#FFFFFF",
                  }}
                >
                  Conhecimentos
                </div>
              </div>

              <div
                className="cardCheck"
                style={{
                  backgroundColor:
                    checkList["isCulturalFitFilled"] && "#2D2D2D",
                  border:
                    !checkList["isCulturalFitFilled"] && "1px solid #E3E3E3",
                  position: "relative",
                }}
                onClick={() => navigate("/fit-cultural")}
              >
                {!checkList["isCulturalFitFilled"] && (
                  <div className="iconNotific"></div>
                )}
                <img
                  src={
                    checkList["isCulturalFitFilled"] ? iconCheck : iconNotCheck
                  }
                  style={{ width: "20px", height: "20px" }}
                  alt="icon"
                />
                <div
                  className="textCard"
                  style={{
                    color: checkList["isCulturalFitFilled"] && "#FFFFFF",
                  }}
                >
                  Fit Cultural
                </div>
              </div>

              <div
                className="cardCheck"
                style={{
                  backgroundColor:
                    checkList["isSelfieLetterFilled"] && "#2D2D2D",
                  border:
                    !checkList["isSelfieLetterFilled"] && "1px solid #E3E3E3",
                  position: "relative",
                }}
                onClick={() => navigate("/selfie-letter")}
              >
                {!checkList["isSelfieLetterFilled"] && (
                  <div className="iconNotific"></div>
                )}

                <img
                  src={
                    checkList["isSelfieLetterFilled"] ? iconCheck : iconNotCheck
                  }
                  style={{ width: "20px", height: "20px" }}
                  alt="icon"
                />
                <div
                  className="textCard"
                  style={{
                    color: checkList["isSelfieLetterFilled"] && "#FFFFFF",
                  }}
                >
                  Selfie Letter
                </div>
              </div>

              <div
                className="cardCheck"
                style={{
                  backgroundColor: user?.avatar && "#2D2D2D",
                  border: !user?.avatar && "1px solid #E3E3E3",
                  position: "relative",
                }}
                onClick={() => navigate("/settings")}
              >
                {!user?.avatar && <div className="iconNotific"></div>}

                <img
                  src={user?.avatar ? iconCheck : iconNotCheck}
                  style={{ width: "20px", height: "20px" }}
                  alt="icon"
                />
                <div
                  className="textCard"
                  style={{
                    color: user?.avatar && "#FFFFFF",
                  }}
                >
                  Adicionar foto
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="footerMobileFixed">
            Desenvolvido com
            <img src={iconHeart} alt="" />
            <strong>© eComLovers®</strong>
          </div>
    </AuthorizedLayout>
  );
};

export default CheckProfile;
