import moment from "moment";
import CryptoJS from "crypto-js";
import api from "./api";

// Função para calcular o hash do arquivo
const hashFile = async (filePath) => {
  try {
    const fileBuffer = await fetch(filePath).then((res) => res.arrayBuffer());
    const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(fileBuffer));
    const hash = CryptoJS.MD5(wordArray);
    const hexDigest = CryptoJS.enc.Base64.stringify(hash);
    return hexDigest;
  } catch (error) {
    console.error("Erro ao calcular o hash:", error);
    throw error;
  }
};

// const MULTIPART_THRESHOLD = 5 * 1024 * 1024; // 5MB
const PART_SIZE = 5 * 1024 * 1024; // 5MB

export const uploadFile = async (file, user, onProgress) => {
  try {
    const hashKey = `ecom-lovers-${moment(new Date()).format(
      "MMMDDYYYY-HHmmss"
    )}`;
    const checksum = await hashFile(file);
    const numberOfParts = Math.ceil(file.size / PART_SIZE);

    // Recebe data?.signed_id e data?.urls[0].url da resposta da API
    const { data } = await api.post(
      `/talents/selfie_letter/create_multipart_upload`,
      {
        data: {
          attributes: {
            parts: Number(numberOfParts),
            key: hashKey,
          },
        },
      }
    );

    if (!data) {
      throw new Error("Parâmetros assinados não encontrados.");
    }

    // Se o arquivo for grande e precisar de upload multipart
    if (data) {
      // console.log("Iniciando upload multipart...");

      // Aqui, ao invés de usar o SDK para criar a multipart upload, fazemos diretamente com a URL assinada
      const uploadId = data?.upload_id; // O data?.signed_id pode ser o uploadId diretamente
      const uploadedParts = [];

      try {
        for (let i = 0; i < numberOfParts; i++) {
          const start = i * PART_SIZE;
          const end = Math.min(start + PART_SIZE, file.size);
          const chunk = file.slice(start, end);
          const chunkBlob = new Blob([chunk], { type: file.type });

          // Upload da parte para a URL assinada (usando fetch ou axios)
          const partResponse = await fetch(data?.urls[i].url, {
            method: "PUT", // Usamos PUT para o upload direto
            headers: {
              "Content-Type": file.type, // ou outro header necessário
            },
            body: chunkBlob,
          });

          if (partResponse.ok) {
            // console.log(`Parte ${i + 1} upload com sucesso.`);
            uploadedParts.push({
              PartNumber: i + 1,
              ETag: partResponse.headers.get("ETag"), // Supondo que o ETag seja retornado
            });
            const progress = Math.round(
              (((i + 1) * PART_SIZE) / file.size) * 100
            );
            onProgress(progress); // Atualiza o progresso aqui
          } else {
            throw new Error(`Erro ao fazer upload da parte ${i + 1}`);
          }
        }

        const parts = uploadedParts.map((item) => ({
          part_number: item.PartNumber,
          etag: item.ETag.replace(/"/g, ""), // Remove as aspas extras de ETag
        }));

        // Após o upload de todas as partes, completar o upload multipart
        await api.post(`/talents/selfie_letter/complete_multipart_upload/`, {
          data: {
            attributes: {
              upload_id: uploadId,
              filename: file.name,
              byte_size: file.size,
              checksum: checksum,
              key: hashKey,
              content_type: file.type,
              metadata: {
                user_id: user.id,
              },
              parts: parts,
            },
          },
        });
      } catch (err) {
        console.error("Erro durante o upload multipart:", err);
        throw err;
      }
    }

    return {
      file: {
        filename: file.name,
        byte_size: file.size,
        checksum: checksum,
        key: hashKey,
        content_type: file.type,
      },
    };
  } catch (error) {
    console.error("Erro no upload:", error);
    throw error;
  }
};
