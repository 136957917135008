import { useContext, useEffect, useRef, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  LinearProgress,
  linearProgressClasses,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { AuthContext } from "../../providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import background from "../../assets/completeProfileBackground.png";
import skillsBackground from "../../assets/Group 1.svg";
import cultureGraph from "../../assets/cultureGraph.svg";
import api from "../../utils/api";
import heart from "../../assets/heartIcon.svg";
import heartInactive from "../../assets/heartIconInactive.svg";
import selfieLetterImg from "../../assets/realistic selfie letther vídeo image.svg";
import iconFIt from "../../assets/image 13.png";
import { Loader } from "../../components/Loader";
import Animation from "../../components/Animation/Animation";
import { Parallax } from "react-scroll-parallax";
import backgroundIcons from "../../assets/Group 1 (1).png";
import iconHeart from "../../assets/Vector (23).png";
import ReactPlayer from 'react-player'

import iconPLay from "../../assets/Vector (1).svg";

import astrounautStandingWave from "../../assets/posesPNG/astronout-standing-wave.png";
import astrounautHoldingTablet from "../../assets/posesPNG/astronout-holding-tablet.png";
import astrounautPointingBoard from "../../assets/posesPNG/astronout-pointing-board.png";
import astrounautStandingRinglight from "../../assets/image 10.png";
import CulturalChart from "./graph";
import { toast } from "react-toastify";
import Slider from "react-slick";

import "./Home.css";
import LoadingIcon from "../../components/LoadingIcon";

export const Home = () => {
  // const [isLoading, setIsLoading] = useState(() => {
  //   return localStorage.getItem("isLoading") === "true" || true;
  // });
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();
  const { user, updateUser, signOut, isLoading, setIsLoading } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [knowledgeList, setKnowledgeList] = useState([]);
  const [otherKnowledgeSkillsList, setOtherKnowledgeSkillsList] = useState([]);

  useEffect(() => {
    // Simule um tempo de carregamento para a animação
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 5000); // Exibe a animação por 3 segundos
      return () => clearTimeout(timer); // Limpa o timer ao desmontar
    }
  }, [isLoading]);

  const culture = user?.isCulturalFitFilled;
  const skills = user?.isTalentKnowledgeSkillFilled;
  const knowledge = user?.isTalentKnowledgeAreaFilled;
  const selfieLetter = user?.isSelfieLetterFilled;

  const removerAposDoisPontos = (texto) => {
    // return texto;
    const indiceDoisPontos = texto.indexOf(":");
    if (indiceDoisPontos !== -1) {
      return texto.substring(0, indiceDoisPontos);
    }
    return texto;
  };

  const handleSkill = (value) => {
    const knowledgeAreas = {
      1: "operation",
      2: "marketing",
      3: "marketplace",
      4: "platform",
      5: "erp",
      6: "designAndMedia",
      7: "dataAndBi",
      8: "socialMedia",
      9: "ia",
    };

    return knowledgeAreas[value] || false;
  };

  const handleKnowledge = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  // console.log(user);

  const getKnowledge = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/knowledge_skills`);

      if (data.data) {
        // const items = data.data.attributes.knowledgeAreaSkills;
        // const nonDontKnowItems = items.filter(
        //   (item) => item.level !== "dont_know"
        // );
        // const result =
        //   nonDontKnowItems.length >= 2
        //     ? nonDontKnowItems.slice(0, 2)
        //     : items.slice(0, 2);
        setKnowledgeList(data.data.attributes.knowledgeAreaSkills);
        setOtherKnowledgeSkillsList(data.data.attributes.otherKnowledgeSkills);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const [videos, setVideos] = useState("");
  const [loadingVideos, setLoadingVideos] = useState(false);

  const getVideos = async () => {
    setLoadingVideos(true);
    try {
      const { data } = await api.get(`/talents/selfie_letter`);
      setVideos(data?.data?.attributes?.selfieLetterUrl || "");
    } catch (error) {
      setLoadingVideos(false);
    } finally {
      setLoadingVideos(false);
    }
  };

  useEffect(() => {
    getKnowledge();
    getVideos();
  }, []);

  // console.log(videos);
  

  const KnowledgeCard = ({ title, value, editable = false, id, idx }) => {
    return (
      <div
        className="KnowledgeCard"
        onClick={() => {
          id ? navigate(`/skills/${id}`) : navigate(`/knowledge#${idx}`);
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <div className="titleCard">{title}</div>
        </div>
        <Rating
          defaultValue={value}
          precision={1}
          max={3}
          icon={<img src={heart} alt="heart" />}
          emptyIcon={<img src={heartInactive} alt="heart" />}
          readOnly={!editable}
        />
      </div>
    );
  };

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
    userAVatar: user?.avatar !== null
  };

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  const [visibleTags, setVisibleTags] = useState([]);
  const [visibleTags1, setVisibleTags1] = useState([]);
  const [visibleTags2, setVisibleTags2] = useState([]);
  const [visibleTags3, setVisibleTags3] = useState([]);
  const [visibleTags4, setVisibleTags4] = useState([]);
  const [visibleTags5, setVisibleTags5] = useState([]);
  const tagsRef = useRef([]);
  const tagsRef1 = useRef([]);
  const tagsRef2 = useRef([]);
  const tagsRef3 = useRef([]);
  const tagsRef4 = useRef([]);
  const tagsRef5 = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageBottom = window.scrollY + window.innerHeight;

      // Verifica quais elementos devem ser visíveis
      const newVisibleTags = tagsRef?.current.map((tag) => {
        return tag?.getBoundingClientRect()?.top < pageBottom ? true : false;
      });

      const newVisibleTags1 = tagsRef1?.current.map((tag) => {
        return tag?.getBoundingClientRect()?.top < pageBottom ? true : false;
      });

      const newVisibleTags2 = tagsRef2?.current.map((tag) => {
        return tag?.getBoundingClientRect()?.top < pageBottom ? true : false;
      });

      const newVisibleTags3 = tagsRef3?.current.map((tag) => {
        return tag?.getBoundingClientRect()?.top < pageBottom ? true : false;
      });

      const newVisibleTags4 = tagsRef4?.current.map((tag) => {
        return tag?.getBoundingClientRect()?.top < pageBottom ? true : false;
      });

      const newVisibleTags5 = tagsRef5?.current.map((tag) => {
        return tag?.getBoundingClientRect()?.top < pageBottom ? true : false;
      });

      setVisibleTags(newVisibleTags);
      setVisibleTags1(newVisibleTags1);
      setVisibleTags2(newVisibleTags2);
      setVisibleTags3(newVisibleTags3);
      setVisibleTags4(newVisibleTags4);
      setVisibleTags5(newVisibleTags5);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigate = () => {
    navigate("/settings/status");
  };

  // const firstFiveItems = user?.dayToDay?.slice(0, 5).map((item) => item.logoUrl);

  const stylesAvatar = [
    {
      display: "flex",
      position: "absolute",
      width: "80px",
      height: "80px",
      right: "40px",
      bottom: "50px",
      borderRadius: "50%",
    },
    {
      display: "flex",
      position: "absolute",
      width: "80px",
      height: "80px",
      left: "40px",
      top: "-50px",
      borderRadius: "50%",
    },
    {
      display: "flex",
      position: "absolute",
      width: "80px",
      height: "80px",
      top: "50px",
      right: "0px",
      borderRadius: "50%",
    },
    {
      display: "flex",
      position: "absolute",
      width: "55px",
      height: "55px",
      bottom: "80px",
      right: "-50px",
      borderRadius: "50%",
    },
    {
      display: "flex",
      position: "absolute",
      width: "45px",
      height: "45px",
      right: "100px",
      borderRadius: "50%",
    },
  ];
  const [randomItems, setRandomItems] = useState([]);

  const getRandomItems = (array, numItems) => {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, numItems);
  };

  // useEffect para definir os itens aleatórios apenas uma vez
  useEffect(() => {
    if (user?.dayToDay) {
      const items = getRandomItems(user.dayToDay, 5);
      setRandomItems(items);
    }
  }, [user]);

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false, // Remover as setas
  };


  return (
    <>
      {isLoading && <Animation />}

      <AuthorizedLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 15px",
            overflow: "hidden",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // width: "100%",
              // marginTop: "25px",
              alignItems: "center",
              // padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {contarTrues(checkList) <= 4 && (
                <div className="containerCardAstr">
                  {/* <Box
                sx={{
                  height: "100%",
                  width: "60%",
                }}
              >
                <div className="textProfile">Complete seu perfil</div>
                <LinearProgress
                  sx={{
                    marginTop: "10px",
                    height: 10,
                    borderRadius: 5,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor: "#BEFCF9",
                      border: "2px solid #BEFCF9",
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                      borderRadius: "5px 0px 0px 5px",
                      backgroundColor: "#57BAB8",
                    },
                  }}
                  value={25}
                  variant="determinate"
                />
                <Box
                  sx={{
                    width: "100%",
                    padding: "8px",
                    gap: "10px",
                  }}
                >
                  <div className="textProfileSUb">
                    Perfis completos e de qualidade aumentam em 4,5 vezes suas
                    chances de ser contratado por clientes.
                  </div>
                </Box>
              </Box>

              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "40%",
                }}
              >
                <img
                  src={astrounautStandingWave}
                  alt="backgroud img"
                  style={{
                    position: "absolute",
                    height: "130%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div> */}
                  <div className="cardCompleteProfile anamitionCard">
                    <div className="cardLeft">
                      <div className="textProfile">Complete seu perfil</div>
                      <LinearProgress
                        sx={{
                          height: 8,
                          borderRadius: 5,
                          [`&.${linearProgressClasses.colorPrimary}`]: {
                            backgroundColor: "#BEFCF9",
                            border: "2px solid #BEFCF9",
                          },
                          [`& .${linearProgressClasses.bar}`]: {
                            borderRadius: "5px 0px 0px 5px",
                            backgroundColor: "#57BAB8",
                          },
                        }}
                        value={((contarTrues(checkList) / 5) * 100).toFixed(2)}
                        variant="determinate"
                      />

                      <div className="textProfileSUb">
                        Perfis completos e de qualidade aumentam em 4,5 vezes
                        suas chances de ser contratado por clientes.
                      </div>

                      <div
                        className="button"
                        onClick={() => {
                          handleNavigate();
                          setIsLoading(false);
                        }}
                      >
                        Clique aqui
                      </div>
                    </div>

                    <div className="cardRigth">
                      <img
                        src={astrounautStandingWave}
                        alt="backgroud img"
                        style={{
                          position: "absolute",
                          width: "131px",
                          top: "-20px",
                          right: "8px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* <div className={`containerHabilits anamitionCard`}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="textTitle">Habilidades</div>
                  <button
                    className="buttonCardHome"
                    onClick={() => {
                      navigate("/skills");
                      setIsLoading(false);
                    }}
                  >
                    Ver Tudo
                  </button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "15px",
                    position: "relative",
                  }}
                >
                  <img
                    src={skillsBackground}
                    style={{ width: "239px" }}
                    alt="a circle with a heart inside"
                  />
                  <div style={{ display: "flex", position: "absolute" }}>
                    <Parallax rotate={[300, 400]}>
                      {randomItems?.map((item, index) => (
                        <div
                          className="cardAVatar"
                          style={stylesAvatar[index]}
                          key={item.id}
                        >
                          <img src={item.logoUrl} alt={item.optionText} />
                        </div>
                      ))}
                    </Parallax>
                  </div>
                </div>
              </div> */}

              {(!knowledgeList || knowledgeList?.length === 0) && !loading ? (
                <div
                  // ref={(el) => (tagsRef.current[0] = el)}
                  className={`containerHabilits anamitionCard`}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="textTitle">Habilidades</div>
                    <button
                      className="buttonCardHome"
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        navigate("/skills");
                        setIsLoading(false);
                      }}
                    >
                      Preencher
                    </button>
                  </div>
                  <Box
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      scrollbarWidth: "none",
                      width: "100%",
                    }}
                  >
                    <div className="textProfileSUb2">
                      Comece a construir seu portfólio de habilidades! Adicione
                      suas áreas de especialização, como SEO, Análise de Dados,
                      Design, ou outras que você domina, para destacar suas
                      competências profissionais.
                    </div>
                  </Box>
                </div>
              ) : (
                <div
                  // ref={(el) => (tagsRef.current[0] = el)}
                  className={`containerHabilits anamitionCard`}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="textTitle">Habilidades</div>
                    <button
                      className="buttonCardHome"
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        navigate("/skills");
                        setIsLoading(false);
                      }}
                    >
                      Ver Tudo
                    </button>
                  </div>
                  <Box
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      scrollbarWidth: "none",
                      width: "100%",
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "80px",
                        }}
                      >
                        <LoadingIcon />
                      </div>
                    ) : (
                      <div
                        className="slider-container"
                        style={{ widows: "100%" }}
                      >
                        <Slider {...settings} className="slideHOme">
                          {knowledgeList?.length > 0 &&
                            knowledgeList.map((item, index) => (
                              <KnowledgeCard
                                key={index}
                                title={item.optionText}
                                value={handleKnowledge(item.level)}
                                id={item.optionId}
                                idx={index}
                              />
                            ))}
                        </Slider>
                      </div>
                    )}
                  </Box>
                </div>
              )}

              {(!otherKnowledgeSkillsList ||
                otherKnowledgeSkillsList?.length === 0) &&
              !loading ? (
                <div
                  // ref={(el) => (tagsRef.current[0] = el)}
                  className={`containerHabilits anamitionCard`}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="textTitle">Conhecimentos</div>
                    <button
                      className="buttonCardHome"
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        navigate("/knowledge");
                        setIsLoading(false);
                      }}
                    >
                      Preencher
                    </button>
                  </div>
                  <Box
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      scrollbarWidth: "none",
                      width: "100%",
                    }}
                  >
                    <div className="textProfileSUb2">
                      Adicione ao seu perfil temas como Gestão de Projetos,
                      Oratória e Copywriting para mostrar suas habilidades no
                      dia a dia e se destacadas ainda mais!
                    </div>
                  </Box>
                </div>
              ) : (
                <div className={`containerHabilits anamitionCard`}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="textTitle">Conhecimentos</div>
                    <button
                      className="buttonCardHome"
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        navigate("/knowledge");
                        setIsLoading(false);
                      }}
                    >
                      Ver Tudo
                    </button>
                  </div>
                  <Box
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      scrollbarWidth: "none",
                      width: "100%",
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "80px",
                        }}
                      >
                        <LoadingIcon />
                      </div>
                    ) : (
                      <div
                        className="slider-container"
                        style={{ widows: "100%" }}
                      >
                        <Slider {...settings} className="slideHOme">
                          {otherKnowledgeSkillsList?.length > 0 &&
                            otherKnowledgeSkillsList.map((item, index) => (
                              <KnowledgeCard
                                key={index}
                                title={item.optionText}
                                value={handleKnowledge(item.level)}
                                idx={index}
                              />
                            ))}
                        </Slider>
                      </div>
                    )}
                  </Box>
                </div>
              )}

              {!culture ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    ref={(el) => (tagsRef2.current[0] = el)} // Usando o primeiro índice para o card
                    className={
                      contarTrues(checkList) <= 4
                        ? `fadein ${
                            visibleTags2[0] ? "visible" : ""
                          } containerHabilits`
                        : "containerHabilits anamitionCard"
                    }
                    style={{ flexDirection: "row" }}
                  >
                    <Box
                      sx={{
                        width: "55%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="textTitle">Fit Cultural</div>
                      <div className="textProfileSUb2">
                        Pronto para decolar com a gente? Responda o seu fit
                        cultural!
                      </div>

                      <button
                        onClick={() => {
                          navigate("/fit-cultural");
                          setIsLoading(false);
                        }}
                        className="buttonCardHome"
                        style={{ marginTop: "20px" }}
                      >
                        Fazer meu fit cultural
                      </button>
                    </Box>
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "45%",
                      }}
                    >
                      <img
                        src={iconFIt}
                        style={{
                          position: "absolute",
                          height: "140px",
                          width: "131.2px",
                          objectFit: "contain",
                          top: "50%",
                          left: "50%",
                          transform:
                            "translate(-50%, -50%)" /* Gira a imagem 180 graus */,
                          transformOrigin:
                            "center" /* Rotaciona a partir do centro */,
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    ref={(el) => (tagsRef3.current[0] = el)} // Usando o primeiro índice para o card
                    className={
                      contarTrues(checkList) <= 4
                        ? `fadein ${
                            visibleTags3[0] ? "visible" : ""
                          } containerHabilits`
                        : "containerHabilits anamitionCard"
                    }
                    // style={{ flexDirection: "row" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="textTitle">Fit Cultural</div>
                      <button
                        className="buttonCardHome"
                        onClick={() => {
                          navigate("/fit-cultural");
                          setIsLoading(false);
                        }}
                      >
                        Ver Tudo
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "4%",
                      }}
                    >
                      {/* <img
                      src={cultureGraph}
                      alt="graph showing different culture types"
                    /> */}
                      <CulturalChart />
                    </div>
                  </div>
                </div>
              )}

              {!selfieLetter ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginBottom: "25px",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    ref={(el) => (tagsRef4.current[0] = el)} // Usando o primeiro índice para o card
                    className={
                      contarTrues(checkList) <= 4
                        ? `fadein ${
                            visibleTags4[0] ? "visible" : ""
                          } containerHabilits`
                        : "containerHabilits anamitionCard"
                    }
                    style={{ flexDirection: "row" }}
                  >
                    <Box
                      sx={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="textTitle"> Selfie Letter</div>
                      <div className="textProfileSUb2">
                        Estamos entusiasmados em conhecer você melhor!
                      </div>

                      <Button
                        color="primary"
                        sx={{
                          marginTop: "15px",
                          backgroundColor: "#EC5569",
                          borderRadius: "80px",
                          color: "white",
                          fontSize: "16px",
                        }}
                        variant="outlined"
                        style={{ textTransform: "none" }}
                        onClick={() => {
                          navigate("/selfie-letter");
                          setIsLoading(false);
                        }}
                      >
                        Enviar Vídeo
                      </Button>
                    </Box>

                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "45%",
                      }}
                    >
                      <img
                        src={astrounautStandingRinglight}
                        style={{
                          position: "absolute",
                          height: "140px",
                          width: "131.2px",
                          objectFit: "contain",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginBottom: "25px",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    ref={(el) => (tagsRef5.current[0] = el)} // Usando o primeiro índice para o card
                    className={
                      contarTrues(checkList) <= 4
                        ? `fadein ${
                            visibleTags5[0] ? "visible" : ""
                          } containerHabilits`
                        : "containerHabilits anamitionCard"
                    }
                    style={{ flexDirection: "column" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="textTitle"> Selfie Letter</div>
                      <Button
                        color="secondary"
                        sx={{
                          backgroundColor: "white",
                          border: "0.8px solid #57BAB8",
                          borderRadius: "80px",
                          color: "#57BAB8",
                          fontSize: "14px",
                        }}
                        variant="outlined"
                        style={{ textTransform: "none" }}
                        onClick={() => {
                          navigate("/selfie-letter");
                          setIsLoading(false);
                        }}
                      >
                        Ver mais
                      </Button>
                    </div>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!loadingVideos ? (
                         <div style={styles.thumbnailContainer}  onClick={() => {
                          navigate("/selfie-letter");
                          setIsLoading(false);
                        }}>
                         {/* <video style={styles.image}>
                           {" "}
                           <source src={videoUrl} />
                           Your browser does not support the video tag.
                         </video> */}
               
                         <ReactPlayer
                           url={videos}
                           width={"100%"}
                           height={"200px"}
                         />
                         <div style={styles.playButtonContainer}>
                           <img src={iconPLay} alt="Play button" />
                         </div>
                       </div>
                      ) : (

                      <img
                        src={selfieLetterImg}
                        alt="video preview"
                        style={{ marginTop: "25px" }}
                      />
                      )}
                      <Button
                        onClick={() => {
                          navigate("/selfie-letter");
                          setIsLoading(false);
                        }}
                        color="primary"
                        sx={{
                          marginTop: "25px",
                          backgroundColor: "white",
                          borderRadius: "80px",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                        variant="outlined"
                        style={{ textTransform: "none" }}
                      >
                        Enviar outro vídeo
                      </Button>
                    </Box>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="footerMobile">
          Desenvolvido com
          <img src={iconHeart} alt="" />
          <strong>© eComLovers®</strong>
        </div>
      </AuthorizedLayout>
    </>
  );
};

const styles = {
  container: {
    position: "relative",
    width: "90vw",
    height: "200px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  thumbnailContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  videoHidden: {
    display: "none",
  },
  playButtonContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  playButton: {
    width: "60px",
    height: "60px",
  },
  video: {
    width: "100%",
    height: "100%",
  },
};
