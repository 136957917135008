import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobileDevice from "../../../utils/mobileCheck";
import systemIcon from "../../../assets/systemIcon.svg";
import "./Checkout.css";
import { Typography } from "@mui/material";
import LayoutSpace from "../../components/LayoutSpace";
import LoadingIcon from "../../../components/LoadingIcon";
import { AuthContext } from "../../../providers/AuthProvider";

const Complete = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobileDevice();
  const { completeCompany } = useContext(AuthContext);
  const valueContext = useContext(AuthContext);

  const stylesImage = { height: "75vh" };

  useEffect(() => {
    completeCompany();
  }, []);

  useEffect(() => {
    console.log(valueContext);
  }, [valueContext]);

  return (
    <LayoutSpace stylesImage={stylesImage}>
      <div className="containerPlans">
        {/* <Loader size={150} /> */}
        <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "150px",
                    marginTop: "50px",
                  }}
                >
                  <LoadingIcon />
                </div>
        <div className="containerCardPlans"></div>
      </div>
    </LayoutSpace>
  );
};

export default Complete;
