import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Box,
  Button,
  Fab,
  Grow,
  Icon,
  IconButton,
  Input,
  LinearProgress,
  linearProgressClasses,
  Rating,
  Typography,
} from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { InputWithLabel } from "../../components/InputWithLabel";
import { useFormik } from "formik";
import profileCharacter from "../../assets/astronaut 3.svg";
import addPhoto from "../../assets/addPhoto.svg";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import unmask from "../../utils/unmask";
import { toast } from "react-toastify";
import moment from "moment";
import heart from "../../assets/heartIcon.svg";
import heartInactive from "../../assets/heartIconInactive.svg";
import info from "../../assets/info.svg";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import iconSEta from "../../assets/chevron-left.png";
import iconHeart from "../../assets/Vector (23).png";
import astrounautStandingWave from "../../assets/posesPNG/astronout-standing-wave.png";

import astrounautStandingThinking from "../../assets/posesPNG/astronout-standing-thinking.png";
import { ExpandLess, ExpandMore, WhatsApp } from "@mui/icons-material";

import "./FAQ.css";

const listFac = [
  {
    question: "Eu trabalho com eCommerce! O que vocês tem para mim?",
    answer:
      "Se você é apaixonado por eCommerce, possui habilidades e procura uma oportunidade a eComLovers é o seu ponto de partida! Se inscreva, entre para a comunidade e seja encontrado por empresas que procuram alguém como você!",
  },
  {
    question: "Ah, então vocês são tipo a Catho, Gupy, Vagas…?",
    answer:
      "Não… a eComLovers é diferente. Somos nichados (eCommerce), o que nos torna especialistas. Em nossa comunidade de talentos não temos profissionais de outras áreas. (Salvo que você seja um iniciante que busca empresas para a sua primeira oportunidade).",
  },
  {
    question: "Eu posso me inscrever? Tem restrição?",
    answer:
      "Se você tem qualquer experiência com eCommerce, você já pode se inscrever. E se você nunca trabalhou com nada parecido, mas fez algum curso, ou busca uma oportunidade de primeiro emprego no setor, também pode se inscrever. Então, resumindo, se inscreve sem medo e vem para comunidade dos apaixonados por eCommerce!",
  },
  {
    question: "Me inscrevi! Quando vou ser chamado para uma vaga?",
    answer:
      "No dia seguinte! Brincadeira 😅. Adoraríamos que isso fosse verdade (As vezes será!). Agora você está em nosso banco de talentos e por hora será um prazer ter + 1 Lover com a gente. As vagas vão surgir e vamos tentar colocar você no e-Commerce que tiver a melhor sinergia com o seu perfil. O seu perfil ficará disponível para as empresas assinantes encontrar você! Mantenha seu perfil atualizado e terá muito mais chance de ser selecionado.",
  },
  {
    question: "Não vi nenhuma vaga disponível ainda… onde estão?",
    answer:
      "O nosso processo de recrutamento e seleção não é o que você está acostumado… As empresas que possuem vagas, futuras posições e oportunidades em seus negócios digitais vão encontrar você aqui na comunidade. Enriqueça o seu perfil com nossas lives, cursos e eventos e esteja sempre pronto! Lembre-se: A sorte é a oportunidade que encontrou alguém preparado.",
  },
  {
    question: "Eita! Quanto custa isso?",
    answer:
      "Um 🌽! É nada… custa o seu tempo para se inscrever e apenas isso. Ah, se você puder indicar e compartilhar com os profissionais da sua rede de contatos que também são apaixonados por eCommerce, seria uma baita troca, não é mesmo?",
  },
];

export const FAQ = () => {
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const QuestionCard = ({ question, answer }) => {
    const [open, setOpen] = useState(false);

    return (
      <Button
        style={{ textTransform: "none", padding: 0, width: "100%" }}
        color={"inherit"}
        onClick={() => setOpen(!open)}
      >
        <Box
          sx={{
            backgroundColor: "#F7F7F7",
            borderRadius: "10px",
            // border: "1px solid #F6F2F3",
            border: "1px solid #F6F2F3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            // boxShadow: "0px 4px 10px 0px #00000033",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "18px",
            }}
          >
            <div className="subtitleFaq3">{question}</div>
            {open ? (
              <ExpandLess style={{ color: "#211E1F", padding: "8px" }} />
            ) : (
              <ExpandMore style={{ color: "#211E1F", padding: "8px" }} />
            )}
          </div>
          <Grow in={open} style={{ transformOrigin: "0 0 0" }}>
            <Box
              sx={{
                padding: "18px",
                background: "#D8F6F5",
                borderRadius: "0px 0px 10px 10px",
                // border: "1px solid #F6F2F3",
                borderTop: "1px solid #BFB7B8",
                display: open ? "flex" : "none",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <div className="subtitleFaq2">{answer}</div>
            </Box>
          </Grow>
        </Box>
      </Button>
    );
  };

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
    userAVatar: user?.avatar !== null
  };

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  const handleNavigate = () => {
    navigate("/settings/status");
  };

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "90vw" : "800px",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              width: "100%",
              flexDirection: "column",
            }}
          >
             {contarTrues(checkList) <= 4 ? (
            <div className="containerCardAstr">
              <div className="cardCompleteProfile anamitionCard">
                <div className="cardLeft">
                  <div className="textProfile">Complete seu perfil</div>
                  <LinearProgress
                    sx={{
                      height: 8,
                      borderRadius: 5,
                      [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor: "#BEFCF9",
                        border: "2px solid #BEFCF9",
                      },
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: "5px 0px 0px 5px",
                        backgroundColor: "#57BAB8",
                      },
                    }}
                    value={((contarTrues(checkList) / 5) * 100).toFixed(2)}
                    variant="determinate"
                  />

                  <div className="textProfileSUb">
                    Perfis completos e de qualidade aumentam em 4,5 vezes suas
                    chances de ser contratado por clientes.
                  </div>

                  <div
                    className="button"
                    onClick={() => {
                      handleNavigate();
                    }}
                  >
                    Clique aqui
                  </div>
                </div>

                <div className="cardRigth">
                  <img
                    src={astrounautStandingWave}
                    alt="backgroud img"
                    style={{
                      position: "absolute",
                      width: "131px",
                      top: "-20px",
                      right: "8px",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Box
            sx={{
              backgroundColor: "#D8F6F5",
              // border: "1px solid #57BAB8",
              borderRadius: "10px",
              width: "100%",
              aspectRatio: 350 / 115,
              marginBottom: "28px",
              display: "flex",
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "60%",
                padding: "24px",
              }}
            >
              <div className="titleFaq">Perguntas Frequentes</div>
              <div className="subtitleFaq">Tem alguma dúvida?</div>
              <div className="subtitleFaq">A gente te explica!</div>
            </Box>

            <div
              style={{
                position: "relative",
                height: "100%",
                width: "40%",
              }}
            >
              <img
                src={astrounautStandingThinking}
                style={{
                  position: "absolute",
                  height: "110%",
                  maxWidth: "100%",
                  objectFit: "contain",
                  top: "40%",
                  left: "45%",
                  transform: "translate(-50%, -50%)",
                }}
                alt=""
              />
            </div>
          </Box>
          )}
        
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "6px",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginBottom: "40px",
                gap: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {listFac.map((item) => (
                <QuestionCard question={item.question} answer={item.answer} />
              ))}
              <a
                href="https://wa.me/5511952939526"
                target="_blank"
                style={{ textDecoration: "none", textTransform: "none" }}
                rel="noreferrer"
              >
                <button className="cardWhatsapp">
                  Ainda tem dúvidas? Vamos conversar!
                </button>
              </a>
            </Box>
          </div>
        </div>
        {/* <Button
          sx={{
            bottom: "20px",
            right: "20px",
            position: "fixed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: "#25d366",
            minWidth: 0,
            padding: isMobile ? 1 : 0.5,

            "&:hover": {
              opacity: "75%",
              backgroundColor: "#25d366",
            },
          }}
          href="https://wa.me/5511952939526"
          target="_blank"
        >
          <WhatsApp
            sx={{
              color: "white",
              width: isMobile ? "50px" : "25px",
              height: isMobile ? "50px" : "25px",
            }}
          />
        </Button> */}
      </div>
      <div className="footerMobile">
        Desenvolvido com
        <img src={iconHeart} alt="" />
        <strong>© eComLovers®</strong>
      </div>
    </AuthorizedLayout>
  );
};
