import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import logo from "../../assets/Logo (1).png";
import sendIcon from "../../assets/Artboard 3 copy 7.svg";
import iconMiojo from "../../assets/miojo.svg";

import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AuthContext } from "../../providers/AuthProvider";
import useIsMobileDevice from "../../utils/mobileCheck";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import selectIcon from "../../assets/Frame 20 (6).png";
import selectedIcon from "../../assets/Frame 20 (5).png";
import planIcon from "../../assets/image 25 (3).svg";
import { ReactComponent as PlanIcon } from "../../assets/image 25 (3).svg";
import "./index.css";
// import { brazilStates } from "../../utils/states";
import { getBase64, removeAfterColon } from "../../utils";
import NewSlider2 from "./components/NewSlider2";

import "./SignUp.css";
import { toast } from "react-toastify";
import axios from "axios";
import { ChatScrollIndicator } from "./components/ChatScrollIndicator";

export const SignUp = () => {
  // const [loading, setLoading] = useState(false);
  const [loading, setLoading] = useState(() => {
    return sessionStorage.getItem("loading") || false;
  });

  const {
    signUp,
    handleQuestion,
    handleAnswer,
    handleAnswerFile,
    handleAnswerKnowledge,
    handleAnswerAreas,
  } = useContext(AuthContext);
  const isMobile = useIsMobileDevice();
  // const [questionIndex, setQuestionIndex] = useState(0);
  const [brazilStates, setBrazilStates] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(() => {
    const savedQuestionIndex = sessionStorage.getItem("questionIndex");
    return savedQuestionIndex !== null ? Number(savedQuestionIndex) : 0;
  });

  const [idState, setdState] = useState(() => {
    const savedQuestionIndex = sessionStorage.getItem("idState");
    return savedQuestionIndex !== null ? Number(savedQuestionIndex) : null;
  });

  const navigate = useNavigate();

  const [nextQuestion, setNextQuestion] = useState(() => {
    const savedNextQuestion = sessionStorage.getItem("nextQuestion");
    try {
      return savedNextQuestion
        ? JSON.parse(savedNextQuestion)
        : savedNextQuestion === "undefined"
        ? undefined
        : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return savedNextQuestion === "undefined" ? undefined : []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });

  const [comment, setComment] = useState(() => {
    return sessionStorage.getItem("comment") || "";
  });
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const [authToken, setAuthToken] = useState(() => {
    return sessionStorage.getItem("authToken") || "";
  });
  const hasRunEffect = useRef(false);
  const [fieldType, setFieldType] = useState(() => {
    return sessionStorage.getItem("fieldType") || "email";
  });
  const [loadingMessage, setLoadingMessage] = useState(false);

  const inputRef = useRef(null);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [tempPhone, setTempPhone] = useState("");
  const [nextExemplo, setNextExemplo] = useState(() => {
    return sessionStorage.getItem("isNextExemplo") === "true"; // Converte a string para booleano
  });

  const [selectKnowledge, setSelectedKnowledge] = useState(() => {
    try {
      const savedKnowledge = sessionStorage.getItem("selectKnowledge");
      return savedKnowledge ? JSON.parse(savedKnowledge) : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });

  const [selectArea, setSelectedArea] = useState(() => {
    try {
      const savedArea = sessionStorage.getItem("selectArea");
      return savedArea ? JSON.parse(savedArea) : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });
  // const [userEmail, setUserEmail] = useState("");
  const [userEmail, setUserEmail] = useState(() => {
    return sessionStorage.getItem("userEmail") || "";
  });

  const [sliedValueItem, setsliedValueItem] = useState(0);

  const [isConversationStarted, setIsConversationStarted] = useState(() => {
    return sessionStorage.getItem("isConversationStarted") === "true"; // Converte a string para booleano
  });

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const { data } = await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        );
        setBrazilStates(data); // Atualiza o estado com os dados recebidos
      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    };

    fetchStates(); // Chama a função assíncrona
  }, []);

  useEffect(() => {
    if (loading) {
      setHasLoadedOnce(true);
    }
  }, [loading]);

  useEffect(() => {
    if (hasLoadedOnce && !loading && inputRef.current) {
      inputRef.current.select();
    }
  }, [loading, hasLoadedOnce]);

  const handleResize = () => {
    scrollToBottom();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [messages, setMessages] = useState(() => {
    try {
      const savedMessages = sessionStorage.getItem("chatMessages");
      return savedMessages ? JSON.parse(savedMessages) : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });

  // Remover mensagens duplicadas
  useEffect(() => {
    const uniqueMessages = Array.from(
      new Map(
        messages.map((msg) => [`${msg.message}-${msg.sender}`, msg])
      ).values()
    );

    if (uniqueMessages.length !== messages.length) {
      setMessages(uniqueMessages);
    }
  }, [messages]);

  const [questions, setQuestions] = useState([
    "Bem-vindo! Estamos felizes em tê-lo na nossa comunidade de apaixonados por eCommerce e o mundo digital.",
  ]);

  const validateBirthdate = Yup.date()
    .required("A data é obrigatória")
    .transform((value, originalValue) => {
      const dataNew = moment(originalValue).format("DD/MM/YYYY");
      const parsedDate = moment(dataNew, "DD/MM/YYYY", true);
      return parsedDate.isValid() ? parsedDate.toDate() : new Date("");
    })
    .test("is-18-or-older", function (value) {
      if (!value || isNaN(value.getTime()))
        return this.createError({ message: "Data inválida" });

      const age = moment().diff(moment(value), "years");

      if (age < 18) {
        const yearsLeft = 18 - age;
        return this.createError({
          message: `Falta(m) ${yearsLeft} ano(s) para seus 18 anos! Queremos você aqui conosco!`,
        });
      }
      return true;
    });

  const validatePhone = Yup.string()
    .matches(
      /^\+55 \(\d{2}\) 9 \d{4}-\d{4}$/,
      "O número de telefone é inválido"
    )
    .required("O telefone é obrigatório");

  const validateEmail = Yup.string()
    .required("O e-mail é obrigatório")
    .email("Formato de e-mail inválido");

  const validateLinkedInUrl = Yup.string()
    .required("O link do LinkedIn é obrigatório")
    .matches(
      /^(https?:\/\/)?(www\.)?(linkedin\.com\/.*)$/,
      "O link deve ser um URL válido do LinkedIn"
    );

  const validationSchema = Yup.object().shape({
    field: Yup.lazy((value) => {
      switch (nextQuestion?.fieldText) {
        case "birthdate":
          return validateBirthdate;
        case "phone":
          return validatePhone;
        case "email":
          return validateEmail;
        case "linkedin_url":
          return validateLinkedInUrl;
        default:
          return Yup.string();
      }
    }),
  });

  const calculateEighteenYearsOldDate = () => {
    const today = moment();
    return today.subtract(18, "years").format("YYYY-MM-DD"); // Formato padrão para inputs de tipo "date"
  };

  const form = useFormik({
    initialValues: {
      field: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Verificação se campo está vazio para tipo 'select'
      handleUserAnswer(values.field);
      form.setValues(form.initialValues);
    },
  });

  // useEffect(() => {
  //   if(nextQuestion?.fieldText === "birthdate") {
  //     form.setFieldTouched("field", calculateEighteenYearsOldDate());
  //   }
  // },[nextQuestion?.fieldText])

  useEffect(() => {
    if (fieldType === "masked") {
      setTempPhone(form.values.field);
    }
    if (fieldType === "file" && form.values !== form.initialValues) {
      form.submitForm();
    }
  }, [form.values.field]);

  useEffect(() => {
    if (!hasRunEffect.current && !isConversationStarted) {
      hasRunEffect.current = true;
      handleInitialMessage();
    }
  }, []);

  useEffect(() => {
    if (nextQuestion?.questionText) {
      handleEffect();
    } else {
      handleComment();
      setLoading(false);
    }
    if (nextQuestion?.knowledgeArea !== "talent_knowledge_area") {
      setSelectedArea(nextQuestion?.options);
      sessionStorage.setItem(
        "selectArea",
        JSON.stringify(nextQuestion?.options)
      );
    }
  }, [nextQuestion]);

  const handleEffect = async () => {
    await handleComment();
    await handleNextQuestion();
  };

  const handleInitialMessage = async () => {
    await systemMessage(questions[0]);
    setQuestionIndex(1);
    sessionStorage.setItem("isConversationStarted", "true");
    const { data } = await handleQuestion();
    setNextQuestion(data[0].attributes);
    sessionStorage.setItem("nextQuestion", JSON.stringify(data[0].attributes));
  };

  const handleComment = async () => {
    if (comment) {
      await systemMessage(comment);
    }
  };

  const handleFieldType = (question) => {
    if (question?.fieldText) {
      switch (question.fieldText) {
        default:
          return "text";
        case "birthdate":
          return "date";
        case "email":
          return "email";
        case "state":
          return "select";
        case "city":
          return "selectCity";
        case "phone":
          return "masked";
        case "avatar":
          return "file";
      }
    } else {
      if (question?.knowledgeArea === "talent_knowledge_area") {
        return "selectKnowledge";
      } else {
        return "grade";
      }
    }
  };

  // console.log(nextQuestion);

  const handleNextQuestion = async () => {
    if (
      nextQuestion?.questionOrder === 10 &&
      messages[messages?.length - 1]?.message !== "testeHabilit"
    ) {
      systemMessage(`testeHabilit`);
    } else {
      if (fieldType === "selectKnowledge") {
        const textMessage = {
          message: nextQuestion.questionText,
          options: nextQuestion?.options,
          type: "selectKnowledge",
          id: nextQuestion?.questionOrder,
        };
        systemMessage(textMessage);
        setLoading(false);
        setQuestionIndex(nextQuestion?.questionOrder);
        sessionStorage.setItem("questionIndex", nextQuestion?.questionOrder);
      } else if (fieldType === "grade") {
        const textMessage = {
          message:
            nextQuestion.questionText ===
            "Seu DIA A DIA com ferramentas como Office, Google Drive, etc."
              ? "Caso esteja testando algo ou queira criar um texto aleatório"
              : nextQuestion.questionText,
          tecnologies: nextQuestion?.options,
          type: "grade",
          id: nextQuestion?.questionOrder,
        };

        userMessage(textMessage);
        setLoading(false);
        setQuestionIndex(nextQuestion?.questionOrder);
        sessionStorage.setItem("questionIndex", nextQuestion?.questionOrder);
      } else {
        await systemMessage(nextQuestion?.questionText);
        setLoading(false);
        setQuestionIndex(nextQuestion?.questionOrder);
        sessionStorage.setItem("questionIndex", nextQuestion?.questionOrder);
      }
    }
  };

  const systemMessage = async (text) => {
    setLoadingMessage(true);
    await delay(1500);
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: text, sender: "system" },
    ]);
    scrollToBottom();
    setLoadingMessage(false);
  };

  const userMessage = async (text) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      typeof text === "string"
        ? { message: text, sender: "talent" } // Caso seja uma string
        : { ...text, sender: "talent" }, // Caso seja um objeto
    ]);
    scrollToBottom();
  };

  const resetsessionStorage = () => {
    sessionStorage.removeItem("questionIndex");
    sessionStorage.removeItem("nextQuestion");
    sessionStorage.removeItem("comment");
    sessionStorage.removeItem("idState");
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("fieldType");
    sessionStorage.removeItem("selectKnowledge");
    sessionStorage.removeItem("selectArea");
    sessionStorage.removeItem("userEmail");
    sessionStorage.removeItem("isConversationStarted");
    sessionStorage.removeItem("chatMessages");
    sessionStorage.removeItem("loading");
  };

  const [loadingNext, setLoadingNext] = useState(false);

  const handleUserAnswer = async (text) => {
    setLoading(true);
    if (!nextQuestion) {
      userMessage("Concluir cadastro!");
    } else if (fieldType === "select") {
      await userMessage(
        brazilStates.find((item) => Number(text) === Number(item.id))?.nome
      );
    } else {
      if (fieldType === "file") {
        if (text === "Não") {
          await userMessage("Deixar para depois");
        } else {
          await userMessage("Imagem enviada!");
        }
      } else if (text === "Updated" || text === "selectKnowledge") {
      } else if (text === "") {
        // await userMessage('Pular etapa');
      } else {
        await userMessage(text);
      }
    }
    try {
      if (!nextQuestion) {
        const { data } = await handleAnswer(questionIndex, userEmail);

        if (data?.attributes) {
          // signUp(data);
          resetsessionStorage();
          navigate("/", { state: { scree: "screen2" } });
        }
      } else {
        if (fieldType === "grade") {
          const { data } = await handleAnswerAreas(
            questionIndex,
            selectArea,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            sessionStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            setSelectedArea([]);
            sessionStorage.setItem("selectArea", JSON.stringify([]));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else if (fieldType === "selectKnowledge") {
          const { data } = await handleAnswerKnowledge(
            questionIndex,
            selectKnowledge,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);

            sessionStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (questionIndex === 9) {
              setNextExemplo(true);
            }
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else if (fieldType === "file" && text !== "Não") {
          const { data } = await handleAnswerFile(
            questionIndex,
            text,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            sessionStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else if (fieldType === "select") {
          const repon = brazilStates.find(
            (it) => Number(it.id) === Number(text)
          ).sigla;
          const { data } = await handleAnswer(questionIndex, repon, authToken);
          if (data?.attributes) {
            if (questionIndex === 1) {
              setUserEmail(text);
            }
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            sessionStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else {
          const { data } = await handleAnswer(questionIndex, text, authToken);
          if (data?.attributes) {
            if (questionIndex === 1) {
              setUserEmail(text);
            }
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            sessionStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
      setLoadingNext(false);
    }
  };

  const messagesEndRef = useRef(null);

  // console.log(nextQuestion);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    getBase64(file).then((result) => {
      file["base64"] = result;
      form.setFieldValue(`field`, result);
    });
  };
  const isDateMessage = (msg) => {
    const date = moment(msg, "YYYY-MM-DD", true); // Verifica se a data está no formato "YYYY/MM/DD"
    return date.isValid();
  };

  const handleToggleKnowledge = (item) => {
    setSelectedKnowledge((currentSelectedKnowledge) => {
      const isSelected = currentSelectedKnowledge.includes(item.id);
      if (isSelected) {
        return currentSelectedKnowledge.filter((id) => id !== item.id);
      } else {
        return [...currentSelectedKnowledge, item.id];
      }
    });

    sessionStorage.setItem("selectKnowledge", JSON.stringify(selectKnowledge));
    form.setFieldValue("field", "selectKnowledge");
  };

  const handleValue = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  const handleAreaLevel = (item, sliderValue) => {
    if (nextExemplo) {
      return; // Não faz alterações quando `nextExemplo` é `true`
    }

    // setMessages((currentMessages) => {
    //   // Clone os dados para evitar mutações diretas
    //   const updatedMessages = [...currentMessages];

    //   // Identifique a última mensagem com `tecnologies`
    //   const lastIndex = currentMessages.length - 1;
    //   const lastMessage = updatedMessages[lastIndex];

    //   if (lastMessage?.tecnologies) {
    //     // Atualize o nível no array `tecnologies`
    //     lastMessage.tecnologies = lastMessage.tecnologies.map((tech) =>
    //       tech.id === item.id ? { ...tech, level: sliderValue } : tech
    //     );
    //   }

    //   return updatedMessages;
    // });

    // sessionStorage.setItem("chatMessages", JSON.stringify(messages));

    // Obtenha as mensagens do sessionStorage
    const currentMessages = messages;

    // Clone os dados para evitar mutações diretas
    const updatedMessages = [...currentMessages];

    // Identifique a última mensagem com `tecnologies`
    const lastIndex = updatedMessages.length - 1;
    const lastMessage = updatedMessages[lastIndex];

    if (lastMessage?.tecnologies) {
      // Atualize o nível no array `tecnologies`
      lastMessage.tecnologies = lastMessage.tecnologies.map((tech) =>
        tech.id === item.id ? { ...tech, level: sliderValue } : tech
      );
    }

    // Salve as mensagens atualizadas no sessionStorage
    sessionStorage.setItem("chatMessages", JSON.stringify(updatedMessages));

    // Atualiza o estado normalmente se `nextExemplo` for `false`
    setSelectedArea((currentSelectedArea) => {
      const updatedArea = currentSelectedArea?.reduce((acc, currentItem) => {
        if (currentItem.id === item.id) {
          acc.push({ ...currentItem, level: sliderValue });
        } else {
          acc.push(currentItem);
        }
        return acc;
      }, []);
      // console.log("Updated Area:", updatedArea);
      return updatedArea;
    });

    sessionStorage.setItem(
      "selectArea",
      JSON.stringify((currentSelectedArea) => {
        const updatedArea = currentSelectedArea?.reduce((acc, currentItem) => {
          if (currentItem.id === item.id) {
            acc.push({ ...currentItem, level: sliderValue });
          } else {
            acc.push(currentItem);
          }
          return acc;
        }, []);
        // console.log("Updated Area:", updatedArea);
        return updatedArea;
      })
    );

    form.setFieldValue("field", "Updated");
  };

  useEffect(() => {
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    sessionStorage.setItem("comment", comment);
  }, [comment]);

  useEffect(() => {
    sessionStorage.setItem("authToken", authToken);
  }, [authToken]);

  useEffect(() => {
    sessionStorage.setItem("loading", loading);
  }, [loading]);

  useEffect(() => {
    sessionStorage.setItem("fieldType", fieldType);
  }, [fieldType]);

  useEffect(() => {
    sessionStorage.setItem("userEmail", userEmail);
  }, [userEmail]);

  useEffect(() => {
    sessionStorage.setItem("isNextExemplo", nextExemplo);
  }, [nextExemplo]);

  const checkInputs = () => {
    // Verifica se há uma mensagem de erro e exibe o toast se necessário
    if (form.errors.field) {
      toast.error(form.errors.field);
    }
  };

  const [municipios, setMunicipios] = useState([]);

  const getMUnicipios = async (id) => {
    try {
      const data = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`
      );
      if (data) {
        setMunicipios(data?.data);
        sessionStorage.setItem("idState", id);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (idState) {
      getMUnicipios(idState);
    }
  }, [idState]);

  // console.log(municipios);

  const ignoreMnesagens = [
    "Seu DIA A DIA com ferramentas como Office, Google Drive, etc.",
    "Ferramentas de Operação que você utiliza",
    "Ferramentas de Marketing e Vendas",
    "Marketplaces que você utiliza",
    "Plataformas que você utiliza",
    "ERPs que você utiliza",
    "Ferramentas de Design e Mídia que você utiliza",
    "Ferramentas de Dados & BI que você utiliza",
    "Ferramentas de Social Media que você utiliza",
    "Ferramentas de IA que você utiliza",
  ];

  const disabledMensagens = [
    "Precisamos do seu melhor Email!",
    "Qual é o seu nome completo?",
    "Qual é a data do seu aniversário?",
    "Qual o seu estado?",
    "Agora precisamos do seu contato...",
  ];

  const loadingCheck = [
    "Precisamos do seu melhor Email!",
    "Qual é o seu nome completo?",
    "Qual é a data do seu aniversário?",
    "Qual o seu estado?",
    "Em qual cidade você está",
    "Agora precisamos do seu contato...",
    "Quais áreas você conhece no ecom?",
    "Excelente, suas áreas de conhecimento e domínio foram anotadas. Vamos em frente, talento!",
    "Agora que você já nos falou que áreas do ecommerce você conhece, vamos criar o seu perfil de habilidades!",
    "Seu DIA A DIA com ferramentas como Office, Google Drive, etc.",
    "Ferramentas de Operação que você utiliza",
    "Ferramentas de Marketing e Vendas",
    "Marketplaces que você utiliza",
    "Plataformas que você utiliza",
    "ERPs que você utiliza",
    "Ferramentas de Design e Mídia que você utiliza",
    "Ferramentas de Dados & BI que você utiliza",
    "Ferramentas de Social Media que você utiliza",
    "Ferramentas de Inteligência Artificial que você utiliza",
    "Tudo certo! Suas ferramentas de IA foram anotadas. Veja a seguir como ficou seu perfil, talento!",
  ];

  const messagesContainerRef = useRef(null);

  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useLayoutEffect(() => {
    const checkScrollPosition = () => {
      if (messagesContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          messagesContainerRef.current;
        // Verifica se o usuário não está no final do container
        const isScrolledUp = scrollHeight - scrollTop > clientHeight + 50; // Buffer de 50px
        setShowScrollIndicator(isScrolledUp);
      }
    };

    checkScrollPosition(); // Verifica imediatamente ao carregar o componente

    const currentContainer = messagesContainerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener("scroll", checkScrollPosition);
      return () => {
        currentContainer.removeEventListener("scroll", checkScrollPosition);
      };
    }
  }, [messagesContainerRef, messages]); // Inclui `messages` nas dependências para recalcular sempre que novas mensagens forem adicionadas

  // console.log(messages);

  const nextQuestio = [
    "Quais destas áreas a seguir você já trabalha no eCommerce?",
    "Ótimo, suas áreas de conhecimento foram registradas! Isso nos ajudará a encontrar as melhores oportunidades para você, talento!",
    "Seu DIA A DIA com ferramentas como Office, Google Drive, etc.",
  ];

  return (
    <div className="containerBoxChatWeb">
      <div className="backgroundCHat"></div>
      <div className="containerChatSignUp">
        <div className="containerHeaderChatSignUp">
          <img
            src={logo}
            alt={""}
            onClick={() => navigate("/", { state: { scree: "screen1" } })}
          />
        </div>
        <div
          style={{
            width: "100vw",
            height: "1px",
            backgroundColor: "#D9D9D966",
            alignSelf: "center",
          }}
        />
        <div className="containerBoxMessagens" ref={messagesContainerRef}>
          {messages?.length > 0 &&
            messages.map((itemPai, index) => (
              <>
                {/* {!ignoreMnesagens.includes(item.message) && (
                  <div className="cardSlideSend2">
                    <div ref={messagesEndRef} />
                  </div>
                )} */}
                {itemPai.message ===
                  "Seu DIA A DIA com ferramentas como Office, Google Drive, etc." &&
                nextExemplo ? (
                  <></>
                ) : itemPai.message ===
                  "Seu DIA A DIA com ferramentas como Office, Google Drive, etc." ? (
                  <>
                    <>
                      {typeof itemPai?.message === "string" &&
                      !itemPai?.type ? (
                        itemPai?.message === "testeHabilit" ? (
                          <>
                            <div className={"systemCard"}>
                              <div>
                                Agora que você já nos falou que áreas do
                                ecommerce você conhece, vamos criar o seu perfl
                                de habilidades!
                              </div>

                              <div style={{ marginTop: "15px" }}>
                                Mas antes, vamos aprender fazendo 1 exemplo
                                fictício?
                              </div>

                              <div style={{ marginTop: "15px" }}>
                                Nos conte qual é o seu nível de habilidade em
                                preparar um macarrão instantâneo 😄.
                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <strong>Arraste para a direita</strong> se você
                                sabe tudo e já é um expert ou{" "}
                                <strong>arraste para esquerda</strong> se você
                                não conhece ou sabe pouco.
                              </div>
                            </div>
                            <div
                              className="containerCarsSlides"
                              style={{ marginBottom: "15px" }}
                            >
                              <div
                                className="cardSlide"
                                style={{ backgroundColor: "#ffffff" }}
                              >
                                <div
                                  className="boxIcon"
                                  style={{ border: "1px solid #E3E3E3" }}
                                >
                                  <img
                                    src={iconMiojo}
                                    alt=""
                                    className="iconTecnology"
                                    // style={{ objectFit: "cover" }}
                                  />
                                </div>
                                <div className="boxTextSlide">
                                  <div className="textCardSend">Miojo</div>

                                  <NewSlider2
                                    key={1000}
                                    item={{
                                      id: 10000,
                                      optionText: "Planilhas",
                                      logoUrl: planIcon,
                                      level:
                                        itemPai.id ===
                                        nextQuestion?.questionOrder
                                          ? 2
                                          : 0,
                                    }}
                                    disable={
                                      itemPai.id === nextQuestion?.questionOrder
                                    }
                                    handleAreaLevel={handleAreaLevel}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            className={
                              itemPai?.sender === "talent"
                                ? "talentCard"
                                : "systemCard"
                            }
                            ref={messagesEndRef}
                          >
                            {isDateMessage(itemPai?.message)
                              ? moment(itemPai?.message, "YYYY/MM/DD").format(
                                  "DD/MM/YYYY"
                                )
                              : itemPai?.message}
                          </div>
                        )
                      ) : itemPai?.type === "grade" ? (
                        <>
                          <div className={"systemCard"}>{itemPai?.message}</div>
                          <div
                            className="containerCarsSlides"
                            style={{ marginBottom: "15px" }}
                          >
                            {itemPai?.tecnologies?.map((item, index) => (
                              <div
                                className="cardSlide"
                                key={index}
                                style={{ backgroundColor: "#ffffff" }}
                              >
                                <div
                                  className="boxIcon"
                                  style={{ border: "1px solid #E3E3E3" }}
                                >
                                  <img
                                    src={item?.logoUrl}
                                    alt=""
                                    className="iconTecnology"
                                  />
                                </div>
                                <div className="boxTextSlide">
                                  <div className="textCardSend">
                                    {item?.optionText}
                                  </div>

                                  <NewSlider2
                                    key={index}
                                    item={{ ...item }}
                                    handleAreaLevel={handleAreaLevel}
                                    disable={
                                      itemPai.id === nextQuestion?.questionOrder
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={"systemCard"}>
                            {itemPai?.message.message}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              gap: "7px",
                              justifyContent: "center",
                              marginBottom: "15px",
                            }}
                          >
                            {itemPai?.message?.options?.map((item, index) => (
                              <button
                                className="cardAreas"
                                onClick={() => handleToggleKnowledge(item)}
                                style={{
                                  backgroundColor:
                                    selectKnowledge.includes(item?.id) &&
                                    "#2D2D2D",
                                  // border:
                                  //   selectKnowledge.includes(item.id) &&
                                  //   "none",
                                  color:
                                    selectKnowledge.includes(item?.id) &&
                                    "#ffffff",
                                  width:
                                    item?.message?.options % 2 !== 0 &&
                                    index ===
                                      item?.message?.options?.length - 1 &&
                                    "97%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    // width: "20%",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      item?.message?.tecnologies?.includes(
                                        item?.id
                                      )
                                        ? selectedIcon
                                        : selectIcon
                                    }
                                    style={{ width: "20px", height: "20px" }}
                                    alt="edit icon"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "80%",
                                    fontSize: "13px",
                                  }}
                                >
                                  {removeAfterColon(item?.optionText)}
                                </div>
                              </button>
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  </>
                ) : (
                  <>
                    {typeof itemPai?.message === "string" && !itemPai?.type ? (
                      itemPai?.message === "testeHabilit" ? (
                        <>
                          <div className={"systemCard"}>
                            <div>
                              Agora que você já nos falou que áreas do ecommerce
                              você conhece, vamos criar o seu perfl de
                              habilidades!
                            </div>

                            <div style={{ marginTop: "15px" }}>
                              Mas antes, vamos aprender fazendo 1 exemplo
                              fictício?
                            </div>

                            <div style={{ marginTop: "15px" }}>
                              Nos conte qual é o seu nível de habilidade em
                              preparar um macarrão instantâneo 😄.
                            </div>
                            <div style={{ marginTop: "15px" }}>
                              <strong>Arraste para a direita</strong> se você
                              sabe tudo e já é um expert ou{" "}
                              <strong>arraste para esquerda</strong> se você não
                              conhece ou sabe pouco.
                            </div>
                          </div>
                          <div
                            className="containerCarsSlides"
                            style={{ marginBottom: "15px" }}
                          >
                            <div
                              className="cardSlide"
                              style={{ backgroundColor: "#ffffff" }}
                            >
                              <div
                                className="boxIcon"
                                style={{ border: "1px solid #E3E3E3" }}
                              >
                                <img
                                  src={iconMiojo}
                                  alt=""
                                  className="iconTecnology"
                                  // style={{ objectFit: "cover" }}
                                />
                              </div>
                              <div className="boxTextSlide">
                                <div className="textCardSend">Miojo</div>

                                <NewSlider2
                                  key={1000}
                                  item={{
                                    id: 10000,
                                    optionText: "Planilhas",
                                    logoUrl: planIcon,
                                    level:
                                      itemPai.id === nextQuestion?.questionOrder
                                        ? 2
                                        : 0,
                                  }}
                                  disable={
                                    itemPai.id === nextQuestion?.questionOrder
                                  }
                                  handleAreaLevel={handleAreaLevel}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className={
                            itemPai?.sender === "talent"
                              ? "talentCard"
                              : "systemCard"
                          }
                          ref={messagesEndRef}
                        >
                          {isDateMessage(itemPai?.message)
                            ? moment(itemPai?.message, "YYYY/MM/DD").format(
                                "DD/MM/YYYY"
                              )
                            : itemPai?.message}
                        </div>
                      )
                    ) : itemPai?.type === "grade" ? (
                      <>
                        {itemPai?.message !==
                          "Caso esteja testando algo ou queira criar um texto aleatório" && (
                          <div className={"systemCard"}>{itemPai?.message}</div>
                        )}
                        <div
                          className="containerCarsSlides"
                          style={{ marginBottom: "15px" }}
                        >
                          {itemPai?.tecnologies?.map((item, index) => (
                            <div
                              className="cardSlide"
                              key={index}
                              style={{ backgroundColor: "#ffffff" }}
                            >
                              <div
                                className="boxIcon"
                                style={{ border: "1px solid #E3E3E3" }}
                              >
                                <img
                                  src={item?.logoUrl}
                                  alt=""
                                  className="iconTecnology"
                                />
                              </div>
                              <div className="boxTextSlide">
                                <div className="textCardSend">
                                  {item?.optionText}
                                </div>

                                <NewSlider2
                                  key={index}
                                  item={{ ...item }}
                                  handleAreaLevel={handleAreaLevel}
                                  disable={
                                    itemPai.id !== nextQuestion?.questionOrder
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={"systemCard"}>
                          {itemPai?.message.message}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "7px",
                            justifyContent: "center",
                            marginBottom: "15px",
                          }}
                        >
                          {itemPai?.message?.options?.map((item, index) => (
                            <button
                              className="cardAreas"
                              onClick={() => handleToggleKnowledge(item)}
                              disabled={
                                itemPai?.message.id !==
                                nextQuestion?.questionOrder
                              }
                              style={{
                                backgroundColor:
                                  selectKnowledge.includes(item?.id) &&
                                  "#2D2D2D",
                                // border:
                                //   selectKnowledge.includes(item.id) &&
                                //   "none",
                                color:
                                  selectKnowledge.includes(item?.id) &&
                                  "#ffffff",
                                width:
                                  itemPai?.message?.options % 2 !== 0 &&
                                  index ===
                                    itemPai?.message?.options?.length - 1 &&
                                  "97%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  // width: "20%",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    item?.message?.tecnologies?.includes(
                                      item.id
                                    )
                                      ? selectedIcon
                                      : selectIcon
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                  alt="edit icon"
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "80%",
                                  fontSize: "13px",
                                }}
                              >
                                {removeAfterColon(item?.optionText)}
                              </div>
                            </button>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ))}

          <div style={{ padding: "2x" }}>
            {loadingMessage && (
              <div className="containerCardSystems">
                <div className="typing">{""}</div>
              </div>
            )}
            <div className="containerCardSystems"></div>
            {fieldType !== "grade" && <div ref={messagesEndRef} />}
          </div>
        </div>

        <ChatScrollIndicator
          containerRef={messagesContainerRef}
          messages={messages}
        />

        <div
          style={{
            width: "100vw",
            height: "1px",
            backgroundColor: "#D9D9D966",
            alignSelf: "center",
          }}
        />
        <div className="containerInputsChat">
          <form
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent:
                fieldType === "selectKnowledge" || fieldType === "grade"
                  ? "flex-end"
                  : "space-between",
              gap: "17px",
            }}
            action="javascript:void(0);"
            onSubmit={(e) => {
              e.preventDefault();
              form.submitForm();
            }}
          >
            {fieldType === "text" && (
              <input
                inputRef={inputRef}
                placeholder={"Digite aqui sua resposta"}
                autoCapitalize={"none"}
                value={form.values.field || ""}
                onChange={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                disabled={
                  !disabledMensagens.includes(
                    messages[messages?.length - 1]?.message
                  )
                }
                className="inputSTyleSend"
              />
            )}
            {fieldType === "email" && (
              <input
                inputRef={inputRef}
                type="email"
                placeholder={"Digite aqui sua resposta"}
                value={form.values.field || ""}
                onChange={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                disabled={
                  !disabledMensagens.includes(
                    messages[messages?.length - 1]?.message
                  )
                }
                className="inputSTyleSend"
                autoComplete="email"
              />
            )}

            {fieldType === "date" && (
              <input
                className="inputSTyleSend ddatWidht"
                type="date"
                placeholder="Data de nascimento"
                label="Data de nascimento"
                autoCapitalize={"none"}
                value={form.values.field || calculateEighteenYearsOldDate()}
                onChange={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onClick={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                disabled={
                  !disabledMensagens.includes(
                    messages[messages?.length - 1]?.message
                  )
                }
              />
            )}

            {fieldType === "selectCity" && (
              <div
                className="selectContainer"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Autocomplete
                  options={municipios || []}
                  getOptionLabel={(option) => option.nome || ""}
                  filterOptions={(options, { inputValue }) => {
                    // Função para limpar o texto removendo acentos, espaços e caracteres especiais
                    const cleanText = (str) => {
                      if (!str) return ""; // Retorna string vazia se for null ou undefined
                      return str
                        .toString() // Converte para string se não for
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "") // Remove acentos
                        .toLowerCase() // Converte para minúsculas
                        .replace(/[.,\-\s]/g, "") // Remove espaços, pontos, vírgulas e hífens
                        .trim();
                    };

                    // Valor de input limpo
                    const cleanInputValue = cleanText(inputValue);

                    return options.filter(
                      (option) =>
                        option &&
                        option.nome &&
                        cleanText(option.nome).includes(cleanInputValue)
                    );
                  }}
                  value={
                    municipios?.find((city) => {
                      const cleanText = (str) => {
                        if (!str) return ""; // Retorna string vazia se for null ou undefined
                        return str
                          .toString() // Converte para string se não for
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toLowerCase()
                          .replace(/[.,\-\s]/g, "")
                          .trim();
                      };

                      return city && city.nome
                        ? cleanText(city.nome) === cleanText(form.values.field)
                        : false;
                    }) || null
                  }
                  onChange={(event, newValue) => {
                    const selectedValue =
                      newValue && newValue.nome ? newValue.nome.trim() : "";
                    form.setFieldValue("field", selectedValue);
                  }}
                  onBlur={() => {
                    form.setFieldValue(
                      "field",
                      (form.values.field || "").trim()
                    );
                    form.setFieldTouched("field", true);
                  }}
                  disabled={loadingMessage}
                  className="inputSTyleSendSelect"
                  renderInput={(params) => (
                    <div
                      ref={params.InputProps.ref}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        {...params.inputProps}
                        placeholder="Insira aqui sua resposta"
                        className="inputCustom"
                      />
                    </div>
                  )}
                  PaperComponent={(props) => (
                    <div
                      {...props}
                      style={{
                        position: "fixed",
                        bottom: "50px",
                        zIndex: 10000,
                        width: "50vw",
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} className="optionCustom">
                      {option.nome}
                    </li>
                  )}
                  noOptionsText={
                    <div style={{ backgroundColor: "#fff", padding: "10px" }}>
                      Cidade não encontrada
                    </div>
                  }
                />
              </div>
            )}

            {/* {fieldType === "selectCity" && (
              <div class="selectContainer">
                <select
                  className="inputSTyleSendSelect"
                  placeholder="Insira aqui sua resposta"
                  value={form.values.field || 12}
                  onChange={(e) => {
                    form.setFieldValue("field", e.target.value);
                  }}
                  onBlur={() => form.setFieldTouched("field", true)}
                  disabled={loading}
                >
                  {municipios?.map((item, index) => (
                    <option
                      key={index}
                      value={item.nome}
                      className="inputListSelect"
                    >
                      {item.nome}
                    </option>
                  ))}
                </select>
              </div>
            )} */}

            {fieldType === "select" && (
              <div class="selectContainer">
                <select
                  className="inputSTyleSendSelect"
                  placeholder="Insira aqui sua resposta"
                  value={form.values.field || 12}
                  onChange={(e) => {
                    form.setFieldValue("field", e.target.value || 12);
                    getMUnicipios(e.target.value);
                  }}
                  onBlur={() => form.setFieldTouched("field", true)}
                  disabled={loadingMessage}
                >
                  {brazilStates
                    .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os estados pelo nome
                    .map((item, index) => (
                      <option
                        key={index}
                        value={item.id}
                        className="inputListSelect"
                      >
                        {item.nome}
                      </option>
                    ))}
                </select>
              </div>
            )}

            {fieldType === "masked" && (
              <ReactInputMask
                mask={"+55 (99) 9 9999-9999"}
                type="tel" // Changed from "number" to "tel"
                value={form.values.field}
                onChange={(e) => form.setFieldValue(`field`, e.target.value)}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                maskChar={""}
                disabled={loadingMessage}
              >
                {() => (
                  <input
                    ref={inputRef}
                    className="inputSTyleSend"
                    aria-label="Digite aqui sua resposta"
                    placeholder="Telefone"
                    autoCapitalize="none"
                    value={form.values.field}
                    disabled={loadingMessage}
                    inputMode="tel" // Changed to "tel" for phone numbers
                  />
                )}
              </ReactInputMask>
            )}

            {fieldType === "file" && (
              <button
                className="buttoninsert"
                style={{
                  backgroundColor: loading && "#F7F7F7",
                  border: loading && "1px solid #E3E3E3",
                  color: loading && "#E3E3E3",
                }}
              >
                <label className="" htmlFor="fupload">
                  <input
                    hidden
                    type="file"
                    id="fupload"
                    name="fupload"
                    accept=".jpeg, .png, .jpg"
                    onChange={handleFileInputChange}
                  />
                  Inserir foto de perfil
                </label>
              </button>
            )}

            {!nextQuestion ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => {
                    setLoadingMessage(true);
                    form.setFieldValue("field", "Concluir");
                    form.submitForm();
                  }}
                  disabled={showScrollIndicator || loadingMessage}
                  className="buttoninsert2"
                  style={{
                    backgroundColor:
                      (showScrollIndicator || loadingMessage) && "#F7F7F7",
                    border:
                      (showScrollIndicator || loadingMessage) &&
                      "1px solid #E3E3E3",
                    color: (showScrollIndicator || loadingMessage) && "#E3E3E3",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Terminar
                </button>
              </div>
            ) : fieldType === "file" ? (
              <button
                onClick={() => form.setFieldValue("field", "Não")}
                disabled={loadingMessage}
                className="buttoninsert2"
                style={{
                  backgroundColor: loading && "#F7F7F7",
                  border: loading && "1px solid #E3E3E3",
                  color: loading && "#E3E3E3",
                }}
              >
                Deixar para depois
              </button>
            ) : fieldType === "selectKnowledge" || fieldType === "grade" ? (
             <div style={{display: 'flex', width: '100%', gap: '15px'}}>
              {!nextQuestio.includes(
                   nextQuestion?.questionText
                  ) && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  
                    <button
                      onClick={async () => {
                        // setLoadingMessage(true);
                        // setLoadingNext(true);
                        // await form.submitForm();
                        // checkInputs();
                        setNextQuestion(null);
                        setLoadingMessage(true);
                        setLoadingNext(true)
                        userMessage('Pular etapa')
                        systemMessage('Cadastro concluído com sucesso! Agora, finalize e faça seu login para começar a usar sua conta')
                      }}
                      disabled={loadingMessage}
                      className={`buttoninsert3 ${loadingNext && 'loading'}`}
                      style={{
                        backgroundColor:
                          (loadingMessage) && "#F7F7F7",
                        border:
                          (loadingMessage) &&
                          "1px solid #E3E3E3",
                        color:
                          (loadingMessage) && "#E3E3E3",
                        fontSize: "16px",
                        fontWeight: "500",
                        // opacity: showScrollIndicator ? "20%" : "100%",
                      }}
                    >
                      {loadingNext ? 'Enviando' : 'Pular etapa'}
                    </button>
      
                </div>
              )}


              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {nextExemplo ? (
                  <button
                    onClick={async () => {
                      setLoadingMessage(true);
                      if (nextQuestion?.questionOrder === 10) {
                        setLoadingMessage(true);
                        await new Promise((resolve) =>
                          setTimeout(resolve, 2000)
                        );
                        setLoadingMessage(true);
                        await systemMessage(
                          "🎉 Parabéns por completar o exemplo do slide! 🚀 Você está indo muito bem! " +
                            "Agora, que tal avançarmos para os itens seguintes? Estou aqui para acompanhar você nesse próximo passo. 💪"
                        );
                        setLoadingMessage(true);
                        await new Promise((resolve) =>
                          setTimeout(resolve, 2000)
                        );
                        setLoadingMessage(true);
                        await systemMessage(nextQuestion?.questionText);
                        setLoadingMessage(true);
                        await new Promise((resolve) =>
                          setTimeout(resolve, 2000)
                        );
                        setNextExemplo(false);
                        handleNextQuestion();
                        setLoadingMessage(false);
                      }
                      // await form.submitForm();
                      // checkInputs();
                    }}
                    className={`buttoninsert2 ${(loading && !loadingNext)&& 'loading'}`}
                    style={{
                      backgroundColor:
                        (showScrollIndicator || loadingMessage) && "#F7F7F7",
                      border:
                        (showScrollIndicator || loadingMessage) &&
                        "1px solid #E3E3E3",
                      color:
                        (showScrollIndicator || loadingMessage) && "#E3E3E3",
                      fontSize: "16px",
                      fontWeight: "500",
                      // opacity: showScrollIndicator ? "100%" : "20%",
                    }}
                    disabled={showScrollIndicator || loadingMessage}
                  >
                    {(loading && !loadingNext) ? 'Enviando' : 'Próximo'}
                  </button>
                ) : (
                  <button
                    onClick={async () => {
                      setLoadingMessage(true);
                      await form.submitForm();
                      checkInputs();
                    }}
                    disabled={showScrollIndicator || loadingMessage}
                    className={`buttoninsert2 ${(loading && !loadingNext) && 'loading'}`}
                    style={{
                      backgroundColor:
                        (showScrollIndicator || loadingMessage) && "#F7F7F7",
                      border:
                        (showScrollIndicator || loadingMessage) &&
                        "1px solid #E3E3E3",
                      color:
                        (showScrollIndicator || loadingMessage) && "#E3E3E3",
                      fontSize: "16px",
                      fontWeight: "500",
                      // opacity: showScrollIndicator ? "20%" : "100%",
                    }}
                  >
                    {(loading && !loadingNext) ? 'Enviando' : 'Próximo'}
                  </button>
                )}
              </div>
             </div>
            ) : (
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  style={{ padding: 0 }}
                  onClick={async () => {
                    await form.submitForm();
                    checkInputs();
                  }}
                  disabled={
                    loadingMessage ||
                    form?.values?.field === null ||
                    form?.values?.field === ""
                  }
                >
                  <img
                    src={sendIcon}
                    alt={""}
                    style={{
                      width: "50px",
                      height: "50px",
                      opacity:
                      loadingMessage ||
                        form?.values?.field === null ||
                        form?.values?.field === ""
                          ? "20%"
                          : "100%",
                    }}
                  />
                </IconButton>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
