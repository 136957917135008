import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useEffect, useState } from "react";
import useIsMobileDevice from "../../../utils/mobileCheck";
import hexToRgba from "../../../utils/hexToRgba";

function valuetext(value) {
  return `${value}°C`;
}

export default function NewSlider2({ item, handleAreaLevel, disable }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [sliderValue, setSliderValue] = useState(item.level);

  // console.log(item);

  const isMobile = useIsMobileDevice();

  const sliderHandleColor = (value) => {
    switch (value) {
      case 0:
        return "black";
      case 1:
        return "#EC5569";
      case 2:
        return "#ECA355";
      case 3:
        return "#009ABC";
      case 4:
        return "#57BAB8";
      default:
        return null;
    }
  };

  // const ValueLabelComponent = (props) => {
  //   const { children, value } = props;
  //   return (
  //     <Tooltip
  //       componentsProps={{
  //         tooltip: {
  //           sx: {
  //             fontWeight: 500,
  //             fontSize: "12px",
  //             lineHeight: "16.8px",
  //             padding: "4px 10px",
  //             borderRadius: "5px",
  //             bgcolor: sliderHandleColor(sliderValue),
  //             "& .MuiTooltip-arrow": {
  //               color: sliderHandleColor(sliderValue),
  //             },
  //             order: 200
  //           },
  //         },
  //       }}
  //       open={isMobile ? true : tooltipOpen}
  //       placement="bottom"
  //       title={value}
  //       arrow
  //     >
  //       {children}
  //     </Tooltip>
  //   );
  // };

  const handleMouseEnter = () => {
    if (!mouseDown) {
      setTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!mouseDown) {
      setTooltipOpen(false);
    }
  };

  const handleMouseDown = () => {
    setMouseDown(true);
    setTooltipOpen(true);
  };

  const handleMouseUp = () => {
    setMouseDown(false);
    setTooltipOpen(false);
  };

  const handleValueLabelFormat = (value) => {
    switch (value) {
      case 0:
        return "Não conheço";
      case 1:
        return "Sei o básico";
      case 2:
        return "Estou aprendendo";
      case 3:
        return "Me viro bem";
      case 4:
        return "Sou expert";
      default:
        return null;
    }
  };

  useEffect(() => {
    setSliderValue(item.level);
  }, [item.level]);

  useEffect(() => {
    const handleMouseUpGlobal = () => {
      if (mouseDown) {
        setMouseDown(false);
        setTooltipOpen(false);
      }
    };

    document.addEventListener("mouseup", handleMouseUpGlobal);

    return () => {
      document.removeEventListener("mouseup", handleMouseUpGlobal);
    };
  }, [mouseDown]);

  return (
    <>
      <Slider
        style={{
          // marginTop: isMobile ? "0px" : "10px",
          margin: "0px",
          width: "100%",
          padding: '0px',

          // marginBottom: isMobile ? "10px" : "0px",
        }}
        sx={{
          "& .MuiSlider-thumb:hover": {
            boxShadow: `0px 0px 0px 8px ${hexToRgba(
              sliderHandleColor(sliderValue),
              0.16
            )}`,
          },
          "& .MuiSlider-thumb.Mui-active": {
            boxShadow: `0px 0px 0px 14px ${hexToRgba(
              sliderHandleColor(sliderValue),
              0.16
            )}`,
          },
          '& .MuiSlider-thumb': {
            width: 20, // Adjust thumb size
            height: 20, // Adjust thumb size
            marginLeft: '-8px',
          },
          '& .MuiSlider-track': {
            height: 10, // Ensure track height matches your design
          },
        }}
        slotProps={{
          track: {
            style: {
              backgroundColor: sliderHandleColor(sliderValue),
              // borderColor: sliderHandleColor(sliderValue),
              border: "0.95px solid #ABABAB",
              height: "15px",
            },
          },
          thumb: {
            style: {
              backgroundColor: "#575757",
              marginLeft: (sliderValue === 0 || sliderValue === undefined) ? '8px' : null              ,
              border: "0.95px solid #ffff",
              ":hover": {
                color: sliderHandleColor(sliderValue),
              },
            },
          },
          rail: {
            style: {
              backgroundColor: "#FFFFFF",
              height: "15px",
              border: "0.95px solid #ABABAB",
              width: "100%",
            },
          },
        }}
        min={0}
        defaultValue={0}
        value={sliderValue}
        onChange={(e) => {
          setSliderValue(e.target.value);
          handleAreaLevel(item, e.target.value);
        }}
        // onMouseDown={handleMouseDown}
        // onMouseUp={handleMouseUp}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        disabled={disable}
        max={4}
        valueLabelFormat={handleValueLabelFormat}
        // step={1}
        valueLabelDisplay="auto"
        // slots={{
        //   valueLabel: ValueLabelComponent,
        // }}
      />
    </>
  );
}
