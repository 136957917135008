import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Box,
  Button,
  Drawer,
  Icon,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Modal,
  Rating,
  Typography,
} from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { InputWithLabel } from "../../components/InputWithLabel";
import { useFormik } from "formik";
import profileCharacter from "../../assets/astronaut 3.svg";
import addPhoto from "../../assets/addPhoto.svg";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import unmask from "../../utils/unmask";
import { toast } from "react-toastify";
import moment from "moment";
import heart from "../../assets/heartIcon.svg";
import heartInactive from "../../assets/heartIconInactive.svg";
import info from "../../assets/info.svg";
import { useNavigate } from "react-router-dom";
import LoadingIcon from "../../components/LoadingIcon";
import iconHeart from "../../assets/Vector (23).png";
import Joyride from "react-joyride";
import Tooltip from "@mui/material/Tooltip";
import astrounautStandingWave from "../../assets/posesPNG/astronout-standing-wave.png";

import astrounautHoldingTablet from "../../assets/posesPNG/astronout-holding-tablet.png";

import "./Knowledge.css";

function isMobileDevice() {
  return window.innerWidth <= 800;
}

const style = {
  width: isMobileDevice() ? "70vw" : "300px",
  bgcolor: "#2D2D2D",
  border: "1px solid #FFFFFF",
  p: "24px",
  borderRadius: "10px",
};

// const KnowledgeExtraCard = ({ title, value, editable = false, item }) => {
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const handleKnowledge = (value) => {
//     const knowledgeAreas = {
//       0: "dont_know",
//       1: "beginner",
//       2: "can_manage",
//       3: "can_teach",
//       4: "expert",
//     };

//     return knowledgeAreas[value] || "dont_know";
//   };

//   const handleRatingChange = async (event, newValue) => {
//     try {
//       await api.put(`/talents/knowledge_skills/update_skill`, {
//         data: {
//           attributes: {
//             id: Number(item.attributes.id),
//             level: handleKnowledge(newValue),
//           },
//         },
//       });
//     } catch (error) {
//       console.log(error);
//     } finally {
//     }
//   };

//   return (
//     <div className="KnowledgeCardKNow">
//       <div
//         style={{
//           display: "flex",
//           marginBottom: "10px",
//           justifyContent: "space-between",
//         }}
//       >
//         <div className="titleKnowCard">{title}</div>
//         <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
//           <img src={info} alt="info" />
//         </IconButton>

//         <Modal
//           open={open}
//           onClose={handleClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={style}>
//             <div className="titleModalKnow">{title}</div>
//             <div className="subTitleModalKnow">{item?.attributes?.description}</div>
//           </Box>
//         </Modal>
//       </div>
//       <Rating
//         defaultValue={value}
//         precision={1}
//         max={3}
//         icon={<img src={heart} alt="heart" />}
//         emptyIcon={<img src={heartInactive} alt="heart" />}
//         readOnly={!editable}
//         onChange={handleRatingChange}
//       />
//     </div>
//   );
// };

const KnowledgeExtraCard = ({
  title,
  value,
  editable = false,
  item,
  index,
}) => {
  const [open, setOpen] = useState(false);
  const { updateUser, user } = useContext(AuthContext);
  // const [tourActive, setTourActive] = useState(() => {
  //   return localStorage.getItem("isOnboardConhecimentos") === "true"
  //     ? false
  //     : true;
  // });

  const [titleTooltip, setTitleTooltip] = useState(value);
  const [showMessageButton, setShowMessageButton] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleKnowledge = (value) => {
    const knowledgeAreas = {
      0: "dont_know",
      1: "beginner",
      2: "can_manage",
      3: "can_teach",
      4: "expert",
    };

    return knowledgeAreas[value] || "dont_know";
  };

  const handleTooltip = (value) => {
    const knowledgeAreas = {
      0: "Quero aprender",
      1: "Sei o básico",
      2: "Me viro bem",
      3: "Posso ensinar",
    };

    return knowledgeAreas[value] || "Quero aprender";
  };

  const handleRatingChange = async (event, newValue) => {
    setTitleTooltip(newValue);
    setShowMessageButton(true); // Mostra o botão

    // Esconde o botão após 2 segundos
    setTimeout(() => setShowMessageButton(false), 2000);

    try {
      const { data: dataCheck } = await api.put(`/talents/knowledge_skills/update_skill`, {
        data: {
          attributes: {
            id: Number(item.id),
            level: handleKnowledge(newValue),
          },
        },
      });
 
      if (dataCheck) {
        const { data } = await api.get(`/talents/talents/${user?.id}`);
        console.log(data);
        
        if (data) {
          updateUser(data);
        }
      }
     
    } catch (error) {
      console.error(error);
    }
  };

  // const steps = [
  //   {
  //     target: ".KnowledgeCardKNow",
  //     content:
  //       "Este é o cartão de conhecimentos adicionais. Aqui você pode visualizar e alterar seu nível de conhecimento.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".titleKnowCard",
  //     content: "Este é o título do conhecimento.",
  //   },
  //   {
  //     target: ".MuiIconButton-root",
  //     content:
  //       "Clique neste ícone para obter mais informações sobre este conhecimento.",
  //   },
  //   {
  //     target: ".MuiRating-root",
  //     content: "Aqui você pode classificar seu nível de conhecimento.",
  //   },
  // ];

  const MessageButton = ({ text }) => {
    return (
      <button
        className="message-button"
        style={{ opacity: showMessageButton ? 1 : 0 }}
      >
        {text}
      </button>
    );
  };
  useEffect(() => {
    // Verifica se há um hash na URL
    const hash = window.location.hash;
    if (hash) {
      // Tenta rolar até o elemento com o id correspondente ao hash
      const element = document.getElementById(hash.slice(1)); // Remove o '#' do hash
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  
  return (
    <>
      <Drawer
        variant="temporary"
        open={open}
        onClose={handleClose}
        anchor="top"
        PaperProps={{
          style: {
            backgroundColor: "#2D2D2D",
            justifyContent: "space-between",
            padding: "30px",
            margin: "auto",
            borderRadius: "24px",
            width: "300px",
            position: "absolute", // Adicionado para posicionar no centro da tela
            top: "45%", // Alinha verticalmente ao centro         // Alinha horizontalmente ao centro
            transform: "translate(-50%, -50%)", // Move o elemento para o centro exato
          },
        }}
      >
        <div>
          <div className="titleModalKnow" style={{ color: "#FFFFFF" }}>
            {title}
          </div>
          <div className="subTitleModalKnow" style={{ color: "#FFFFFF" }}>
            {item?.description}
          </div>
        </div>
      </Drawer>

      <div>
        {/* {index === 0 && (
        <Joyride
          steps={steps}
          run={tourActive}
          continuous={true}
          showSkipButton={false}
          showProgress={false}
          locale={{
            back: "Voltar",
            close: "Fechar",
            last: "Finalizar",
            next: "Próximo",
            skip: "Pular",
          }}
          callback={(data) => {
            if (data.status === "finished" || data.status === "skipped") {
              setTourActive(false);
              localStorage.setItem("isOnboardConhecimentos", "true");
            }
          }}
        />
      )} */}

        <div className="KnowledgeCardKNow" id={index}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="titleKnowCard">{title}</div>
            <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
              <img src={info} alt="info" />
            </IconButton>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Rating
              defaultValue={value}
              precision={1}
              max={3}
              icon={<img src={heart} alt="heart" />}
              emptyIcon={<img src={heartInactive} alt="heart" />}
              readOnly={!editable}
              onChange={handleRatingChange}
              sx={{
                "& .MuiRating-icon": {
                  margin: "2px", //espaço entre os corações
                },
              }}
            />
            {showMessageButton && (
              <MessageButton text={handleTooltip(titleTooltip)} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const KnowledgeCard = ({ title, value, editable = false, item }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="KnowledgeCardKNow">
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div className="titleKnowCard">{title}</div>
        <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
          <img src={info} alt="info" />
        </IconButton>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="titleModalKnow">{title}</div>
            <div className="subTitleModalKnow">{item?.description}</div>
          </Box>
        </Modal>
      </div>
      <Rating
        defaultValue={value}
        precision={1}
        max={3}
        icon={<img src={heart} alt="heart" />}
        emptyIcon={<img src={heartInactive} alt="heart" />}
        readOnly={!editable}
      />
    </div>
  );
};

export const Knowledge = () => {
  const [selectedMenu, setSelectedMenu] = useState("extraSkills");
  const [knowledge, setKnowledge] = useState([]);
  const [knowledgeExtra, setKnowledgeExtra] = useState([]);
  const isMobile = useIsMobileDevice();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const { user, updateUser, signOut } = useContext(AuthContext);
  const [selectKnowledge, setSelectedKnowledge] = useState([]);

  const removerAposDoisPontos = (texto) => {
    // return texto;
    const indiceDoisPontos = texto.indexOf(":");
    if (indiceDoisPontos !== -1) {
      return texto.substring(0, indiceDoisPontos);
    }
    return texto;
  };

  const handleKnowledge = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  const getKnowledge = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/knowledge_skills`);

      if (data.data) {
        // setKnowledge(data?.data?.attributes?.knowledgeAreaSkills);
        setKnowledgeExtra(data?.data?.attributes?.otherKnowledgeSkills);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getKnowledge();
  }, [selectedMenu]);

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
    userAVatar: user?.avatar !== null
  };

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  const handleNavigate = () => {
    navigate("/settings/status");
  };

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "100%" : "100%",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              width: "100%",
              flexDirection: "column",
            }}
          >
            {contarTrues(checkList) <= 4 ? (
              <div className="containerCardAstr">
                <div className="cardCompleteProfile anamitionCard">
                  <div className="cardLeft">
                    <div className="textProfile">Complete seu perfil</div>
                    <LinearProgress
                      sx={{
                        height: 8,
                        borderRadius: 5,
                        [`&.${linearProgressClasses.colorPrimary}`]: {
                          backgroundColor: "#BEFCF9",
                          border: "2px solid #BEFCF9",
                        },
                        [`& .${linearProgressClasses.bar}`]: {
                          borderRadius: "5px 0px 0px 5px",
                          backgroundColor: "#57BAB8",
                        },
                      }}
                      value={((contarTrues(checkList) / 5) * 100).toFixed(2)}
                      variant="determinate"
                    />

                    <div className="textProfileSUb">
                      Perfis completos e de qualidade aumentam em 4,5 vezes suas
                      chances de ser contratado por clientes.
                    </div>

                    <div
                      className="button"
                      onClick={() => {
                        handleNavigate();
                      }}
                    >
                      Clique aqui
                    </div>
                  </div>

                  <div className="cardRigth">
                    <img
                      src={astrounautStandingWave}
                      alt="backgroud img"
                      style={{
                        position: "absolute",
                        width: "131px",
                        top: "-20px",
                        right: "8px",
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#BEFCF9",
                  border: "1px solid #57BAB8",
                  borderRadius: "10px",
                  width: "100%",
                  aspectRatio: 350 / 135,
                  marginBottom: "28px",
                  display: "flex",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "60%",
                    padding: "24px",
                  }}
                >
                  <div className="titleSKillsCard">
                    Atualização de conhecimentos
                  </div>
                  <div className="subTitleSKillsCard">
                    Aprimore suas especialidades para se destacar no mercado.
                  </div>
                </Box>

                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "40%",
                  }}
                >
                  <img
                    src={astrounautHoldingTablet}
                    style={{
                      position: "absolute",
                      height: "115%",
                      maxWidth: "100%",
                      objectFit: "contain",
                      top: "40%",
                      left: "40%",
                      transform: "translate(-50%, -50%)",
                    }}
                    alt=""
                  />
                </div>
              </Box>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "6px",
              justifyContent: "space-around",
              // paddingRight: "6px",
              // paddingLeft: "6px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "#1E1E1E",
                borderRadius: "20px",
                marginBottom: "40px",
              }}
            >
              <div className="containerButonsKnow">
                <div className="containerBackgroundButons"></div>
                {/* <button
                  className="buttonSelectKnow"
                  style={{
                    backgroundColor: selectedMenu === "yourSkills" && "#F7F7F7",
                    color: selectedMenu === "yourSkills" && "#2D2D2D",
                  }}
                  onClick={() => {
                    setSelectedMenu("yourSkills");
                  }}
                >
                  Seus conhecimentos
                </button> */}
                <button
                  className="buttonSelectKnow"
                  style={{
                    backgroundColor:
                      selectedMenu !== "extraSkills" && "#F7F7F7",
                    color: selectedMenu !== "extraSkills" && "#2D2D2D",
                  }}
                  onClick={() => {
                    setSelectedMenu("extraSkills");
                  }}
                >
                  Conhecimentos Adicionais
                </button>
              </div>

              {selectedMenu === "yourSkills" ? (
                <Box
                  sx={{
                    padding: "25px",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "0px 0px 20px 20px",
                  }}
                >
                  <div className="textKnow">
                    Esses conhecimentos refletem as suas principais habilidades
                    e são automaticamente adicionados. Eles mostram o que você
                    já domina! Para explorar novas áreas, vá para{" "}
                    <strong>Conhecimentos Adicionais.</strong>
                  </div>

                  <div
                    style={{
                      gap: "12px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <KnowledgeCard title={"Gestão de Pessoas"} value={3} />
                    <KnowledgeCard title={"Tráfego"} value={1} />
                    <KnowledgeCard title={"SEO"} value={2} />
                    <KnowledgeCard title={"Análise de Dados"} value={2} />
                    <KnowledgeCard title={"Gestão de Produtos"} value={2} />
                    <KnowledgeCard title={"Atendimento ao Cliente"} value={3} /> */}

                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // height: "150px",
                          width: '200px',
                          marginTop: "50px",
                        }}
                      >
                        <LoadingIcon />
                      </div>
                    ) : (
                      knowledge?.length > 0 &&
                      knowledge.map((item, index) => (
                        <KnowledgeCard
                          key={index}
                          title={item.optionText}
                          value={handleKnowledge(item.level)}
                          item={item}
                        />
                      ))
                    )}
                  </div>
                </Box>
              ) : (
                <Box
                  sx={{
                    padding: "25px",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "0px 0px 20px 20px",
                  }}
                >
                  <div className="textKnow">
                    Quer expandir ainda mais suas competências? Aqui você pode
                    adicionar conhecimentos extras e destacar tudo o que mais te
                    interessa.
                  </div>

                  <div
                    style={{
                      gap: "12px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "150px",
                          marginTop: "50px",
                        }}
                      >
                        <LoadingIcon />
                      </div>
                    ) : (
                      knowledgeExtra?.length > 0 &&
                      knowledgeExtra.map((item, index) => (
                        <KnowledgeExtraCard
                          key={index}
                          title={item.optionText}
                          value={handleKnowledge(item.level)}
                          editable
                          item={item}
                          index={index}
                        />
                      ))
                    )}

                    {/* <KnowledgeCard
                      title={"Gestão de Pessoas"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard title={"Tráfego"} value={0} editable />
                    <KnowledgeCard title={"SEO"} value={0} editable />
                    <KnowledgeCard
                      title={"Análise de Dados"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard
                      title={"Gestão de Produtos"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard
                      title={"Atendimento ao Cliente"}
                      value={0}
                      editable
                    /> */}
                  </div>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>
      <div className="footerMobile">
        Desenvolvido com
        <img src={iconHeart} alt="" />
        <strong>© eComLovers®</strong>
      </div>
    </AuthorizedLayout>
  );
};
