import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

import { AuthContext } from "../../providers/AuthProvider";
import TokenInput from "./components/TokenInput";
import starBackground from "../../assets/Frame 427320198.svg";
import icon1 from "../../assets/Captura de Tela 2024-06-18 às 19.42.20 1.png";
import icon2 from "../../assets/iconLogin1.png";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import iconHeart from "../../assets/Vector (23).png";

import "./Login.css";
import api from "../../utils/api";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenUrl = location.state?.scree;
  const { signIn } = useContext(AuthContext);
  const [loadingToken, setLoadingToken] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentScreen, setCurrentScreen] = useState(() => {
    const currentcreenSecion = sessionStorage.getItem("currentScreenLogin");
    return currentcreenSecion !== null
      ? currentcreenSecion
      : screenUrl
      ? screenUrl
      : "screen1";
  });
  const [isAccepted, setIsAccepted] = useState(() => {
    return sessionStorage.getItem("acceptedTerms") === "true"; // Converte a string para booleano
  });


  const [token, setToken] = useState(Array(6).fill(""));
  const [checkToken, setCheckToken] = useState(false);

  const formLogin = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    const { email } = values;
    setLoadingToken(true);
    try {
      const { data } = await api.post(`/talents/request_password`, {
        email: email,
      });
      console.log(data);
      
      setLoadingToken(false);
      toast.success("Nova senha enviada para o seu email");
      setIsAccepted(data?.accepted_terms);
      sessionStorage.setItem("acceptedTerms", `${data?.accepted_terms}`);
      sessionStorage.setItem("emailLogin", email);
      sessionStorage.setItem("currentScreenLogin", "screen3");
      setCurrentScreen("screen3");
    } catch (error) {
      toast.error("e-Mail não cadastrado. Tente outro 😉");
    } finally {
      setLoadingToken(false);
    }
  };

  const joinStrings = (strings) => {
    return strings.join("");
  };

  const handleLogin = async () => {
    const emailLogin = sessionStorage.getItem("emailLogin");

    if (checkAllStringsFilled(token)) {
      try {
        setLoading(true);
        await signIn({
          email: emailLogin || formLogin.values.email,
          password: joinStrings(token),
        });
        setLoading(false);
        sessionStorage.removeItem("acceptedTerms");
        sessionStorage.removeItem("currentScreenLogin");
        sessionStorage.removeItem("emailLogin");
      } catch (error) {
        console.log(error);

        toast.error("Senha inválida");
      } finally {
        setLoading(false);
      }
    } else {
      setCheckToken(true);
    }
  };

  const checkAllStringsFilled = (strings) => {
    return strings.every((str) => str.trim() !== "");
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case "screen2":
        return (
          <div className="contentContainer2">
            <div className="title" style={{ width: "100%", marginTop: "20px" }}>
              Olá, entre por aqui!
            </div>

            <div className="containerInput">
              <div className="text">Preencha com o seu email</div>
              <input
                type="email"
                label="E-mail"
                className="inputLogin"
                onChange={formLogin.handleChange}
                onBlur={formLogin.handleBlur}
                value={formLogin.values.email}
                name="email"
              />
              {formLogin.touched.email && formLogin.errors.email && (
                <div style={{ color: "red" }} className="text">
                  {formLogin.errors.email}
                </div>
              )}
            </div>

            <div className="contianerButtonsLogin">
              <div
                className="buttonLogin style1"
                onClick={formLogin.handleSubmit}
              >
                {loadingToken ? (
                  <span className="loading">Carregando...</span>
                ) : (
                  "Enviar token de acesso"
                )}
              </div>
            </div>
          </div>
        );
      case "screen3":
        return (
          <div className="contentContainer2">
            {/* <img
              src={currentScreen === "screen1" ? icon1 : icon2}
              alt="icon"
              className={
                currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"
              }
            /> */}
            <div
              className="title"
              style={{
                marginTop: "60px",
                width: "85%",
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              Acesse seu email e preencha com o token que foi enviado!
            </div>

            <div className="containerInput" style={{ height: "" }}>
              <div className="text">Preencha com o token</div>
              <TokenInput token={token} setToken={setToken} />
              <div style={{ color: "red" }} className="text">
                {checkToken && "token é obrigatório"}
              </div>
            </div>

            {!isAccepted && (
              <div className="termosBox">
                <p>
                  Ao clicar em "Entrar", você concorda com os
                  <a
                    href="http://ecomlovers.com/termos-de-uso"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    termos de uso{" "}
                  </a>{" "}
                  e
                  <a
                    href="http://ecomlovers.com/privacidade"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    política de privacidade{" "}
                  </a>
                  .
                </p>
              </div>
            )}


            <div className="contianerButtonsLogin">
              <button className={`buttonLogin style1`} onClick={handleLogin}>
                {loading ? (
                  <span className="loading">Carregando...</span>
                ) : (
                  "Entrar"
                )}
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div className="contentContainer">
            <img
              src={currentScreen === "screen1" ? icon1 : icon2}
              alt="icon"
              className={
                currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"
              }
            />
            <div className="title">Bem-Vindo a eComLovers!</div>
            <div className="subtitle">
              Pronto para decolar em uma jornada incrível no mundo do Ecommerce?
            </div>

            <div
              className="contianerButtonsLogin"
              style={{ marginTop: "10px", marginBottom: "50px" }}
            >
              <div
                className="buttonLogin style1"
                onClick={() => {
                  navigate("/cadastro");
                }}
              >
                Vamos começar agora!
              </div>
              <div
                className="style2"
                onClick={() => setCurrentScreen("screen2")}
                style={{ marginTop: "10px", cursor: "pointer" }}
              >
                Acessar minha conta
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="containerLogin">
      <div className="containerContentALl">
        <div
          style={{
            height: currentScreen === "screen1" ? "200px" : "",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "15px",
            gap: "5px",
          }}
          className="backGrounDivid"
        >
          <img
            src={logo}
            alt="logo"
            className="logo"
            onClick={() => {
              setCurrentScreen("screen1");
              sessionStorage.removeItem("currentScreenLogin");
            }}
          />

          <div className="lineLOgin" />
        </div>
        <div className="contentCONtainerLogin">
          {/* <img
            src={starBackground}
            alt="star background"
            className="imgBackground"
          /> */}
          {/* <img
          src={currentScreen === "screen1" ? icon1 : icon2}
          alt="icon"
          className={currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"}
        /> */}

          {renderScreen(currentScreen)}
        </div>
        <div className="footerLOgin">
          Desenvolvido com
          <img src={iconHeart} alt="" />
          <strong>© eComLovers®</strong>
        </div>
      </div>
    </div>
  );
};

export default Login;
