import React, { useContext } from "react";
import { SidebarLink } from "./styles";
import home from "../../assets/Group 3.svg";
import settings from "../../assets/Group 6.svg";
import knowledge from "../../assets/Group 4.svg";
import skills from "../../assets/Group 9 (1).svg";
import iconFaq from "../../assets/Group 2.svg";
import iconAcademy from "../../assets/Group 5 (1).svg";
import { AuthContext } from "../../providers/AuthProvider";

import "./listItems.css";

export const MainListItems = () => {
  const { setIsLoading } = useContext(AuthContext);

  return (
    <div className="containerListItens">
      <SidebarLink to={"/home"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <div className="containerIMgMenus">
            <img src={home} alt="house icon" />
          </div>

          <div className="textMenu">Início</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/knowledge"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <div className="containerIMgMenus">
            <img src={knowledge} alt="medal icon" />
          </div>
          <div className="textMenu">Conhecimentos</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/skills"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <div className="containerIMgMenus">
            <img src={skills} alt="briefcase icon" />
          </div>
          <div className="textMenu">Habilidades</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/settings"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <div className="containerIMgMenus">
            <img src={settings} alt="gear icon" />
          </div>
          <div className="textMenu">Perfil</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/faq"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <div className="containerIMgMenus">
            <img src={iconFaq} alt="gear icon" />
          </div>
          <div className="textMenu">
            Ajuda
          </div>
        </div>
      </SidebarLink>

      {/* <SidebarLink to={"/"}> */}
        <div className="itemList" onClick={() => setIsLoading(false)} style={{ position :'relative'}}>
          <div className="containerIMgMenus">
            <img src={iconAcademy} alt="gear icon" />
          </div>
          <div className="textMenu">Academy</div>

          <div className="breve">EM BREVE</div>
        </div>
      {/* </SidebarLink> */}

      {/* <SidebarItem
        button
        sx={{ position: "absolute", bottom: 0 }}
        onClick={() => signOut()}
      >
        <ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
          <Logout sx={{ color: "#211e1f" }} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ style: { fontSize: fontSize } }}
          primary="Sair"
        />
      </SidebarItem> */}
    </div>
  );
};
