import React, { useContext, useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import VideoThumbnail from "./components/VideoThumbnail";
import LoadingIcon from "../../components/LoadingIcon";
import { AuthContext } from "../../providers/AuthProvider";
import astronauta from "../../assets/astronauta-perfil.png";
import iconHeart from "../../assets/Vector (23).png";
import { uploadFile } from "../../utils/aws";
import api from "../../utils/api";
import astrounautStandingWave from "../../assets/posesPNG/astronout-standing-wave.png";

import "./SelfieLetter.css";
import CenterMode from "./components/CenterMode";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SelfieLetter = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useContext(AuthContext);
  const [videos, setVideos] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const getVideos = async () => {
    setLoadingVideos(true);
    try {
      const { data } = await api.get(`/talents/selfie_letter`);
      setVideos(data?.data?.attributes?.selfieLetterUrl || "");
      setLoadingVideos(false);
    } catch (error) {
      setLoadingVideos(false);
    }
  };

  const deleteVideos = async () => {
    setLoadingDelete(true);
    try {
      await api.delete(`/talents/selfie_letter/delete`);
      const { data } = await api.get(`/talents/talents/${user?.id}`);
      if (data) {
        updateUser(data);
        setLoadingDelete(false);
        window.location.reload();
      }
    } catch (error) {
      setLoadingDelete(false);
    }
  };

  const [progress, setProgress] = useState(0);

  const handleVideo = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLoading(true);
      try {
        await uploadFile(selectedFile, user, (percent) => {
          setProgress(Math.min(percent, 100)); // Garante que o progresso não passe de 100%
        });

        const { data } = await api.get(`/talents/talents/${user?.id}`);
        if (data) {
          updateUser(data);
        }
        setLoading(false);
        setProgress(0);
        window.location.reload();
        toast.success("Video enviado com sucesso");
      } catch (error) {
        console.error("Erro ao enviar o video:", error);
        toast.error("Erro ao enviar o video");
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    getVideos();
  }, []);

  function isMobileDevice() {
    return window.innerHeight <= 800;
  }

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
    userAVatar: user?.avatar !== null,
  };

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  const handleNavigate = () => {
    navigate("/settings/status");
  };

  return (
    <AuthorizedLayout>
      {loadingVideos ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "150px",
            width: "250px",
            marginTop: "50px",
          }}
        >
          <LoadingIcon />
        </div>
      ) : (
        <div className="containerSelfieLetter">
          {contarTrues(checkList) <= 4 ? (
            <>
              <div
                className="containerCardAstr"
                style={{ marginBottom: "0px" }}
              >
                <div className="cardCompleteProfile anamitionCard">
                  <div className="cardLeft">
                    <div className="textProfile">Complete seu perfil</div>
                    <LinearProgress
                      sx={{
                        height: 8,
                        borderRadius: 5,
                        [`&.${linearProgressClasses.colorPrimary}`]: {
                          backgroundColor: "#BEFCF9",
                          border: "2px solid #BEFCF9",
                        },
                        [`& .${linearProgressClasses.bar}`]: {
                          borderRadius: "5px 0px 0px 5px",
                          backgroundColor: "#57BAB8",
                        },
                      }}
                      value={((contarTrues(checkList) / 5) * 100).toFixed(2)}
                      variant="determinate"
                    />

                    <div className="textProfileSUb">
                      Perfis completos e de qualidade aumentam em 4,5 vezes suas
                      chances de ser contratado por clientes.
                    </div>

                    <div
                      className="button"
                      onClick={() => {
                        handleNavigate();
                      }}
                    >
                      Clique aqui
                    </div>
                  </div>

                  <div className="cardRigth">
                    <img
                      src={astrounautStandingWave}
                      alt="backgroud img"
                      style={{
                        position: "absolute",
                        width: "131px",
                        top: "-20px",
                        right: "8px",
                      }}
                    />
                  </div>
                </div>
              </div>

            </>
          ) : (
            <div className="cardSendVideo">
              <div className="containerText">
                <div className="title">Selfie Letter</div>
                <div className="subtitle1">
                 Estamos torcendo para as empresas assistirem o seu vídeo! 
                </div>
                <div className="subtitle2">
                A Selfie Letter é uma oportunidade para você se apresentar de maneira autêntica. Quer atualizar a sua?
                </div>

                <label
                  className={`buttonSend ${loading && "loading"}`}
                  htmlFor="fupload"
                  style={{ "--progress": `${progress}%` }} // Define a variável CSS para o progresso
                >
                  <div htmlFor="fupload">
                    {loading
                      ? `${progress}%`
                      : videos !== ""
                      ? "Enviar Outro Vídeo"
                      : "Enviar Vídeo"}
                  </div>
                  <input
                    type="file"
                    id="fupload"
                    name="fupload"
                    className="fupload form-control"
                    accept=".mp4, .avi, .mov, .mkv, .wmv"
                    onChange={handleVideo}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <img src={astronauta} alt="SelfieLetter icon" />
            </div>
          )}

          {videos === "" ? (
            <div
              style={{
                // margin: "10px 15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="webSelfie"
            >
               {contarTrues(checkList) <= 4 && (
               <div className="containerTextNew">
                  <div className="title">Envie Sua Selfie Letter</div>
                  <div className="titleContemt" style={{marginBottom :'10px'}}>
                  Grave um vídeo contando quem é você para as empresas que podem te encontrar aqui na comunidade e aumente as suas oportunidades! 
                  </div>
                </div>
               )}

              <div
                className="titleContemt"
                style={{ width: isMobileDevice() ? "80vw" : "90%", marginBottom :'30px' }}
              >
                Deixamos algumas dicas abaixo para você enviar o seu melhor vídeo. Estamos ansiosos para ver sua apresentação!  🙌
              </div>

              <CenterMode />

              {/* <label className={`buttonSelfie ${loading && "loading"}`}>
                {loading ? "Enviando" : " Enviar Vídeo"}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleVideo}
                />
              </label> */}

              <label
                className={`buttonSelfie ${loading && "loading"}`}
                htmlFor="fupload"
                style={{ "--progress": `${progress}%` }} // Define a variável CSS para o progresso
              >
                <div htmlFor="fupload">
                  {loading ? `${progress}%` : "Enviar Vídeo"}
                </div>
                <input
                  type="file"
                  id="fupload"
                  name="fupload"
                  className="fupload form-control"
                  accept=".mp4, .avi, .mov, .mkv, .wmv"
                  onChange={handleVideo}
                  style={{ display: "none" }}
                />
              </label>
            </div>
          ) : (
            <div style={{ padding: "10px 15px" }}>
              <div>
                <VideoThumbnail videoUrl={videos} />
              </div>

              <div
                className={`buttonRemove ${loadingDelete && "loading"}`}
                onClick={() => deleteVideos()}
              >
                Remover Vídeo
              </div>
            </div>
          )}
          <div
            className={
              videos === ""
                ? "footerMobile"
                : isMobileDevice()
                ? "footerMobile"
                : "footerMobileFixed"
            }
          >
            Desenvolvido com
            <img src={iconHeart} alt="" />
            <strong>© eComLovers®</strong>
          </div>
        </div>
      )}
    </AuthorizedLayout>
  );
};

export default SelfieLetter;
