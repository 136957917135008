import React, { useState, useEffect } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const LoadingIcon = ({ isLoading = true }) => {
  // const [fillPercentage, setFillPercentage] = useState(0);

  // useEffect(() => {
  //   if (!isLoading) return;

  //   const interval = setInterval(() => {
  //     setFillPercentage(prev => {
  //       if (prev >= 100) return 0;
  //       return prev + 5;
  //     });
  //   }, 50);

  //   return () => clearInterval(interval);
  // }, [isLoading]);

  return (
    // <svg 
    //   width="55" 
    //   height="46" 
    //   viewBox="0 0 55 46" 
    //   fill="none" 
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path 
    //     fillRule="evenodd" 
    //     clipRule="evenodd" 
    //     d="M21.3022 35.0779C22.6371 35.0779 23.9174 35.6136 24.8613 36.5671C25.8053 37.5206 26.3356 38.8138 26.3356 40.1623V40.1651C26.3356 40.8328 26.2054 41.4939 25.9525 42.1108C25.6995 42.7277 25.3288 43.2882 24.8614 43.7603C24.394 44.2325 23.8391 44.607 23.2284 44.8625C22.6177 45.118 21.9632 45.2495 21.3022 45.2495C20.6412 45.2495 19.9867 45.118 19.376 44.8625C18.7653 44.607 18.2104 44.2325 17.743 43.7603C17.2756 43.2882 16.9048 42.7277 16.6519 42.1108C16.3989 41.4939 16.2687 40.8328 16.2687 40.1651V40.1623C16.2688 38.8138 16.7991 37.5206 17.743 36.5671C18.687 35.6136 19.9672 35.0779 21.3022 35.0779ZM41.2893 35.0779H41.2896C42.6247 35.0779 43.905 35.6136 44.849 36.5672C45.793 37.5207 46.3234 38.8141 46.3234 40.1626V40.1647C46.3234 41.5133 45.793 42.8066 44.849 43.7602C43.905 44.7138 42.6247 45.2495 41.2896 45.2495H41.2893C39.9543 45.2496 38.6739 44.7139 37.7298 43.7603C36.7858 42.8067 36.2554 41.5134 36.2553 40.1647V40.1626C36.2554 38.814 36.7858 37.5207 37.7298 36.5671C38.6739 35.6135 39.9543 35.0778 41.2893 35.0779ZM3.38788 0.752425H7.8295C10.1743 0.686495 12.8405 2.25702 13.1592 4.79151C13.4584 7.16994 13.7516 9.42895 13.7516 9.42895H51.949C52.3459 9.42895 54.6727 9.72311 54.1696 12.4209C53.6665 15.1186 50.8783 30.7235 50.0728 33.4522C49.6968 34.7968 49.1254 35.3592 47.5073 36.3562C45.7589 32.0539 37.2863 30.3174 34.6268 37.553H27.9645C26.4765 31.7023 18.186 30.7099 15.238 36.5033C13.9899 35.6963 12.6858 34.2908 12.2708 31.8683C11.4032 26.8011 8.56972 6.58675 8.56972 6.58675C8.56972 6.58675 8.52535 5.54638 7.6814 5.53957C6.83745 5.53275 2.49955 5.53957 2.49955 5.53957C2.49955 5.53957 0.7229 5.54705 0.7229 3.14603C0.7229 0.745005 3.38788 0.752425 3.38788 0.752425ZM31.3115 18.5217C27.6374 12.5076 19.1952 18.5144 23.7609 23.2408C27.6329 27.2223 29.3742 29.0715 31.3115 31.0046C33.1247 29.1403 35.0129 27.1987 38.8621 23.2408C43.4683 18.5144 35.0129 12.5076 31.3115 18.5217Z" 
    //     fill="#F7BBC3"
    //   />
    //   <path 
    //     fillRule="evenodd" 
    //     clipRule="evenodd" 
    //     d="M21.3022 35.0779C22.6371 35.0779 23.9174 35.6136 24.8613 36.5671C25.8053 37.5206 26.3356 38.8138 26.3356 40.1623V40.1651C26.3356 40.8328 26.2054 41.4939 25.9525 42.1108C25.6995 42.7277 25.3288 43.2882 24.8614 43.7603C24.394 44.2325 23.8391 44.607 23.2284 44.8625C22.6177 45.118 21.9632 45.2495 21.3022 45.2495C20.6412 45.2495 19.9867 45.118 19.376 44.8625C18.7653 44.607 18.2104 44.2325 17.743 43.7603C17.2756 43.2882 16.9048 42.7277 16.6519 42.1108C16.3989 41.4939 16.2687 40.8328 16.2687 40.1651V40.1623C16.2688 38.8138 16.7991 37.5206 17.743 36.5671C18.687 35.6136 19.9672 35.0779 21.3022 35.0779ZM41.2893 35.0779H41.2896C42.6247 35.0779 43.905 35.6136 44.849 36.5672C45.793 37.5207 46.3234 38.8141 46.3234 40.1626V40.1647C46.3234 41.5133 45.793 42.8066 44.849 43.7602C43.905 44.7138 42.6247 45.2495 41.2896 45.2495H41.2893C39.9543 45.2496 38.6739 44.7139 37.7298 43.7603C36.7858 42.8067 36.2554 41.5134 36.2553 40.1647V40.1626C36.2554 38.814 36.7858 37.5207 37.7298 36.5671C38.6739 35.6135 39.9543 35.0778 41.2893 35.0779ZM3.38788 0.752425H7.8295C10.1743 0.686495 12.8405 2.25702 13.1592 4.79151C13.4584 7.16994 13.7516 9.42895 13.7516 9.42895H51.949C52.3459 9.42895 54.6727 9.72311 54.1696 12.4209C53.6665 15.1186 50.8783 30.7235 50.0728 33.4522C49.6968 34.7968 49.1254 35.3592 47.5073 36.3562C45.7589 32.0539 37.2863 30.3174 34.6268 37.553H27.9645C26.4765 31.7023 18.186 30.7099 15.238 36.5033C13.9899 35.6963 12.6858 34.2908 12.2708 31.8683C11.4032 26.8011 8.56972 6.58675 8.56972 6.58675C8.56972 6.58675 8.52535 5.54638 7.6814 5.53957C6.83745 5.53275 2.49955 5.53957 2.49955 5.53957C2.49955 5.53957 0.7229 5.54705 0.7229 3.14603C0.7229 0.745005 3.38788 0.752425 3.38788 0.752425ZM31.3115 18.5217C27.6374 12.5076 19.1952 18.5144 23.7609 23.2408C27.6329 27.2223 29.3742 29.0715 31.3115 31.0046C33.1247 29.1403 35.0129 27.1987 38.8621 23.2408C43.4683 18.5144 35.0129 12.5076 31.3115 18.5217Z" 
    //     fill="#EC5569"
    //     clipPath="url(#loading-clip)"
    //   />
    //   <defs>
    //     <clipPath id="loading-clip">
    //       <rect 
    //         x="0" 
    //         y={`${100 - fillPercentage}%`} 
    //         width="55" 
    //         height={`${fillPercentage}%`} 
    //       />
    //     </clipPath>
    //   </defs>
    // </svg>
    <DotLottieReact
    src="https://lottie.host/b81e3c1b-2559-490d-b62e-1e112edc81e0/Xbkq3dH1Nr.lottie"
    loop
    autoplay
  />
  );
};

export default LoadingIcon;