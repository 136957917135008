import React, { useState, useLayoutEffect } from "react";

export const ChatScrollIndicator = ({
  containerRef,
  messages,
  className = "scroll-indicator",
}) => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useLayoutEffect(() => {
    const checkScrollPosition = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        // Verifica se o usuário não está no final do container
        const isScrolledUp = scrollHeight - scrollTop > clientHeight + 50; // Buffer de 50px
        setShowScrollIndicator(isScrolledUp);
      }
    };

    checkScrollPosition(); // Verifica imediatamente ao carregar o componente

    const currentContainer = containerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener("scroll", checkScrollPosition);
      return () => {
        currentContainer.removeEventListener("scroll", checkScrollPosition);
      };
    }
  }, [containerRef, messages]); // Inclui `messages` nas dependências para recalcular sempre que novas mensagens forem adicionadas

  const handleScrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  // Só renderiza se houver mais mensagens e o usuário não estiver no final
  if (!showScrollIndicator) return null;

  return (
    <>
      <style>
        {`
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(8px); /* Movimento para baixo */
            }
          }
        `}
      </style>
      <div
        className={className}
        // onClick={handleScrollToBottom}
        style={{
          position: "absolute",
          bottom: "103px", // Distância ajustada da área de entrada do chat
          // left: "45%",
          right: "8px",
          // transform: "translateX(-50%)",
          cursor: "pointer",
          zIndex: 1000,
          animation: "bounce 1.5s infinite", // Animação de "pulso",
          width: "28px",
          height: "28px",
          backgroundColor: "#EC5569",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#FFFFFF" // Azul vibrante para destaque
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </div>
    </>
  );
};
